import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Typography,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import "./HseSavedReportModal.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { config } from "../../config";
import axios from "../../APIs/axios";
import { toast, ToastContainer } from "react-toastify";
import ExportSavedReportPDF from "../ExportSavedReportPDF/ExportSavedReportPDF";
import ExportWordDoc from "../ExportWordDoc/ExportWordDoc";
import { getAccountDetails } from "../Services/localStorage";
import ExportExcel from "../ExportExcel/ExportExcel";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Chart from "react-apexcharts";
import html2canvas from "html2canvas";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";
import Loader from "../Loader/Loader";
import VariantsModal from "../VariantsModal/VariantsModal";
import InfoIcon from "@mui/icons-material/Info";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CheckIcon from "@mui/icons-material/Check";
import ObservationsDrawer from "../ObservationsDrawer/ObservationsDrawer";
import DeleteIcon from "@mui/icons-material/Delete";

const removeDuplicates = (observations) => {
  const uniqueObservations = Array.from(
    new Map(observations.map((item) => [item.observation, item])).values()
  );
  return uniqueObservations;
};

const HseSavedReportModal = ({
  selectedReportData,
  setOpenSavedReport,
  allData,
  reportHeader,
  getAllHseData,
  module,
  setOpenReportList,
  getAllHseReports,
  exp,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [screenNumber, setScreenNumber] = useState(exp === true ? 12 : 1);
  const [selectedDateTime, setSelectedDateTime] = useState(
    selectedReportData.date_time
  );
  const [orgList, setOrgList] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState({
    label: selectedReportData.organization,
    value: selectedReportData.org_id,
  });
  const [selectedSite, setSelectedSite] = useState({
    label: selectedReportData.site,
    value: selectedReportData.site,
  });
  const [startDate, setStartDate] = useState(selectedReportData.start_date);
  const [endDate, setEndDate] = useState(selectedReportData.end_date);
  const [siteOptions, setSiteOptions] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedArea, setSelectedArea] = useState(
    selectedReportData.Areas.map((e) => e.area)
  );
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    selectedReportData.Categories
      ? selectedReportData.Categories.map((e) => e.category)
      : []
  );
  const [selectedSector, setSelectedSector] = useState({
    label: selectedReportData.sector_type || "",
    value: selectedReportData.sector_type || "",
  });
  const [selectedParam, setSelectedParam] = useState(
    Array.from(
      new Set(selectedReportData.AllObservations.map((e) => e.table_type))
    ).map((table_type) => ({ label: table_type, value: table_type }))
  );

  // const [AllObservations, setAllObservations] = useState(
  //   selectedReportData.AllObservations
  // );
  const [AllObservations, setAllObservations] = useState(
    removeDuplicates(selectedReportData.AllObservations)
  );
  const [selectedObservations, setSelectedObservations] = useState(
    selectedReportData.AllObservations.filter((e) => e.is_selected === 1)
  );

  const [recommendations, setRecommendations] = useState(
    selectedReportData.Recommendations
  );
  const [criticalObservations, setCriticalObservations] = useState([]);
  const [otherDetails, setOtherDetails] = useState(
    selectedReportData.other_details
  );
  const [backgroundBrief, setBackgroundBrief] = useState(
    selectedReportData.background_brief
      ? selectedReportData.background_brief
      : reportHeader.background_brief
  );
  const [introduction, setIntroduction] = useState(
    selectedReportData.introduction
      ? selectedReportData.introduction
      : reportHeader.introduction
  );
  const [contents, setContents] = useState(
    selectedReportData.contents
      ? selectedReportData.contents.replace("Scoring Table", "Charts")
      : reportHeader.contents.replace("Scoring Table", "Charts")
  );
  const [exeSummary, setExeSummary] = useState(
    selectedReportData.exe_summary
      ? selectedReportData.exe_summary
      : reportHeader.exe_summary
  );
  const [conclusion, setConclusion] = useState(
    selectedReportData.conclusion
      ? selectedReportData.conclusion
      : reportHeader.conclusion
  );
  const [bestPractice, setbestPractice] = useState(
    selectedReportData.best_practice
      ? selectedReportData.best_practice
      : reportHeader.best_practice
  );
  const [theWayForward, setTheWayForward] = useState(
    selectedReportData.the_way_forward
      ? selectedReportData.the_way_forward
      : reportHeader.the_way_forward
  );
  const [imageUrlsByRow, setImageUrlsByRow] = useState(
    JSON.parse(selectedReportData.image_urls)
      ? JSON.parse(selectedReportData.image_urls)
      : {}
  );
  const { userId, name } = getAccountDetails();
  const [editedObservations, setEditedObservations] = useState([]);
  // const [editedRecommendations, setEditedRecommendations] = useState([]);
  // const [editedAreas, setEditedAreas] = useState([]);
  // const [editedCategories, setEditedCategories] = useState([]);
  // const [editedCriticalities, setEditedCriticalities] = useState([]);
  // const [editedIsReference, setEditedIsReference] = useState([]);
  const [isReportEdited, setIsReportEdited] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentEditedRow, setCurrentEditedRow] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState([]);
  const [scorePercent, setScorePercent] = useState(0);
  const [chartImage, setChartImage] = useState(null);
  const chartContainerRef = useRef(null);
  const [disableSaveNext, setDisableSaveNext] = useState(false);
  const [waitForCharts, setWaitForCharts] = useState(true);
  const regexForBackground = /\bon.*?by\b/;
  const regexForExeSummary = /\bon.*?and\b/;
  const regexForAreas = /\bentire[\s\S]*?and\b/;
  const [loading, setLoading] = useState(false);
  const [area, setArea] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [openVairantModal, setOpenVariantModal] = useState(false);
  const [observationVariants, setObservationVariants] = useState([]);
  const [editedFields, setEditedFields] = useState([]);
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [facilityInfo, setFacilityInfo] = useState({});
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [paramList, setParamList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [globalSearchTerm, setGlobalSearchTerm] = useState("");
  const [newRowInputs, setNewRowInputs] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const initializeNewRowInputs = (tableType, sectorType) => {
    setNewRowInputs((prev) => ({
      ...prev,
      [tableType]: {
        table_type: tableType, // Fixed key for table type
        sector_type: sectorType, // Include sector type
        is_selected: 1, // Fixed value for is_selected
        area: "",
        check_points: "",
        observation: "",
        criticality: "",
        recommendations: "",
        is_reference: "",
        score: "",
        imageUrls: [],
      },
    }));
  };

  useEffect(() => {
    updateBackgroundBrief();
    updateExecSummary();
    getFacilityInfo();
  }, [
    selectedOrganization,
    selectedSite,
    startDate,
    endDate,
    selectedArea,
    selectedSector,
  ]);

  useEffect(() => {
    getParamList();
  }, [selectedSector]);

  const getFacilityInfo = async () => {
    const response = await axios.get(
      `${config.PATH}/api/get-facility-info/${selectedReportData.report_id}`
    );
    const data = response.data;
    // Update each field individually
    setFacilityInfo(data);
  };

  //   // Update each field individually
  //   setFacilityInfo(prevState => ({
  //     ...prevState,
  //     name: data.name,
  //     address: data.address,
  //     coordinates: data.coordinates,
  //     propertyDescription: data.property_description,
  //     constructionType: data.construction_type,
  //     workerFootFall: data.worker_footfall,
  //     noc: data.noc,
  //     guidelines: data.guidelines,
  //     category: data.category,
  //   }));
  // };

  const updateBackgroundBrief = () => {
    const updatedData = selectedReportData.background_brief
      .replace(
        selectedReportData.organization,
        `${
          selectedOrganization
            ? selectedOrganization.label
            : selectedReportData.organization
        }`
      )
      .replace(
        selectedReportData.site,
        `${selectedSite ? selectedSite.label : selectedReportData.site}`
      )
      .replace(
        regexForBackground,
        `on ${new Date(startDate).getDate()}-${
          new Date(startDate).getMonth() + 1
        }-${new Date(startDate).getFullYear()} and ${new Date(
          endDate
        ).getDate()}-${new Date(endDate).getMonth() + 1}-${new Date(
          endDate
        ).getFullYear()} by`
      );
    setBackgroundBrief(updatedData);
  };

  const updateExecSummary = () => {
    const updatedData = selectedReportData.exe_summary
      .replace(
        `${selectedReportData.organization}(${selectedReportData.site})`,
        `${
          selectedOrganization
            ? selectedOrganization.label
            : selectedReportData.organization
        }(${selectedSite ? selectedSite.label : selectedReportData.site})`
      )
      .replace(
        regexForExeSummary,
        `on ${new Date(startDate).getDate()}-${
          new Date(startDate).getMonth() + 1
        }-${new Date(startDate).getFullYear()} till ${new Date(
          endDate
        ).getDate()}-${new Date(endDate).getMonth() + 1}-${new Date(
          endDate
        ).getFullYear()} and`
      )
      .replace(
        regexForAreas,
        `entire ${selectedArea.map((e) => e).join(", ")} and`
      );
    setExeSummary(updatedData);
  };

  const fetchDataForCharts = () => {
    const observations = selectedObservations || [];

    const totalScore =
      observations.length > 0
        ? observations
            .map((e) => e.score || 0)
            .reduce((acc, score) => acc + score, 0)
        : 0;

    const percentage = Math.floor(
      (totalScore / (observations.length * 5)) * 100
    );

    setScorePercent(isNaN(percentage) ? 0 : percentage);
    setData(observations);
  };

  // const convertChartToImage = async () => {
  //   try {
  //     if (chartContainerRef.current) {
  //       const contentHeight = chartContainerRef.current.scrollHeight;
  //       chartContainerRef.current.style.height = `${contentHeight}px`;

  //       const canvas = await html2canvas(chartContainerRef.current, {
  //         scrollY: -window.scrollY,
  //       });

  //       chartContainerRef.current.style.height = null;

  //       const image = canvas.toDataURL("image/png");
  //       setChartImage(image);
  //     }
  //   } catch (error) {
  //     console.log("Error converting div to image:", error);
  //   }
  // };

  const convertChartToImage = async () => {
    try {
      if (!chartContainerRef.current) {
        console.error("chartContainerRef is null or undefined");
        return;
      }

      const contentHeight = chartContainerRef.current.scrollHeight;
      chartContainerRef.current.style.height = `${contentHeight}px`;

      const canvas = await html2canvas(chartContainerRef.current, {
        scrollY: -window.scrollY,
      });

      chartContainerRef.current.style.height = null;

      const image = canvas.toDataURL("image/png");
      setChartImage(image);
    } catch (error) {
      console.log("Error converting div to image:", error);
    }
  };

  useEffect(() => {
    if (screenNumber === 10) {
      fetchDataForCharts();
      const timeoutId = setTimeout(() => {
        convertChartToImage();
      }, 1000); // Adjust the delay as needed (e.g., 1000 milliseconds)

      // Clear the timeout if the component unmounts or screenNumber changes
      return () => clearTimeout(timeoutId);
    }
  }, [screenNumber, selectedObservations, convertChartToImage]);

  useEffect(() => {
    setWaitForCharts(true);
    if (screenNumber === 10) {
      // Simulate loading for 3 seconds
      const loaderTimeout = setTimeout(() => {
        setWaitForCharts(false);
      }, 5000);

      // Cleanup timeout if the component unmounts or when loading is complete
      return () => clearTimeout(loaderTimeout);
    }
  }, [screenNumber]);

  // const handleCellEdit = (e, index, field, originalContent, observationObj) => {
  //   setIsEditing(true);
  //   let currentContent;
  //   if (field === "area" || field === "category") {
  //     currentContent = e.value;
  //   } else {
  //     currentContent = e.target.textContent;
  //   }

  //   // Check if the current content exceeds the character limit
  //   if (
  //     (field === "area" || field === "category") &&
  //     currentContent.length > 50
  //   ) {
  //     toast.warning("Only 50 characters are allowed in this field.");
  //     setIsEditing(false);
  //     return; // Do nothing and exit the function
  //   }

  //   if (currentContent.length > 7000) {
  //     toast.warning("Maximum character limit reached.");
  //     e.target.textContent = currentContent.substring(0, 7000);
  //     const range = document.createRange();
  //     const sel = window.getSelection();
  //     range.setStart(e.target.childNodes[0], 7000);
  //     range.collapse(true);
  //     sel.removeAllRanges();
  //     sel.addRange(range);
  //     e.preventDefault();
  //     return;
  //   }
  //   setIsReportEdited(true);
  //   if (currentEditedRow !== -1 && currentEditedRow !== index) {
  //     toast.warning(
  //       "Please save changes in the currently edited row before editing another row."
  //     );
  //     return;
  //   }

  //   setCurrentEditedRow(index);
  //   setIsEditing(true);

  //   let value;

  //   if (field === "area" || field === "category") {
  //     value = e.value;
  //   } else if (field === "criticality") {
  //     value = e.target.value;
  //   } else {
  //     value = e.target.textContent;
  //   }

  //   // const value = field === "criticality" ? e.target.value : e.target.textContent;

  //   const updatedObservations = [...editedObservations];
  //   let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
  //   updatedObservations[i] = {
  //     ...observationObj,
  //     ...updatedObservations[i],
  //     [field]: value,
  //     selectedRefIndex: index,
  //   };

  //   setEditedObservations(updatedObservations);

  //   // Check if the content has been reverted to the original
  //   const isReverted =
  //     field === "observation"
  //       ? e.target.textContent === originalContent
  //       : field === "recommendations"
  //       ? e.target.textContent === originalContent
  //       : field === "area"
  //       ? e.value === originalContent
  //       : field === "criticality"
  //       ? e.target.value === originalContent
  //       : field === "is_reference"
  //       ? e.target.textContent === originalContent
  //       : field === "category"
  //       ? e.value === originalContent
  //       : false;

  //   // If content is reverted, disable editing and enable other rows
  //   if (isReverted) {
  //     setCurrentEditedRow(-1);
  //     setIsEditing(false);
  //     setEditedObservations([]);
  //   }
  // };

  const handleCellEdit = (e, index, field, originalContent, observationObj) => {
    // Function to handle the confirmation dialog
    const handleConfirmation = () => {
      if (!confirmationShown) {
        const confirmEdit = window.confirm(
          "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
        );
        if (!confirmEdit) {
          // Revert the text content if the user cancels
          e.target.textContent = originalContent;
          return false;
        } else {
          setConfirmationShown(true); // User confirmed, set the flag
          return true;
        }
      }
      return true;
    };

    // Show the confirmation dialog if the field is "observation"
    if (field === "observation") {
      if (!handleConfirmation()) {
        setIsEditing(false);
        return; // Exit if the user cancels the confirmation
      }
    }

    setIsEditing(true);

    // Function to update edited fields array
    const updateEditedFields = (newField) => {
      if (!editedFields.includes(newField)) {
        setEditedFields((prevFields) => [...prevFields, newField]);
      }
    };

    if (field === "area") {
      let area = [];
      area.push(e.value);
      setArea(area);
      updateEditedFields("A");
    }

    let currentContent;
    if (field === "area" || field === "category" || field === "criticality") {
      currentContent = e.value;
    } else {
      currentContent = e.target.textContent;
    }

    // Check if the current content exceeds the character limit
    if (
      (field === "area" || field === "category") &&
      currentContent.length > 50
    ) {
      toast.warning("Only 50 characters are allowed in this field.");
      setIsEditing(false);
      return; // Do nothing and exit the function
    }

    if (currentContent.length > 7000) {
      toast.warning("Maximum character limit reached.");
      e.target.textContent = currentContent.substring(0, 7000);
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(e.target.childNodes[0], 7000);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      e.preventDefault();
      return;
    }

    setIsReportEdited(true);
    if (currentEditedRow !== -1 && currentEditedRow !== index) {
      toast.warning(
        "Please save changes in the currently edited row before editing another row."
      );
      return;
    }

    setCurrentEditedRow(index);

    let value;
    if (field === "area" || field === "category" || field === "criticality") {
      value = e.value;
    } else {
      value = e.target.textContent;
    }

    const updatedObservations = [...editedObservations];
    let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
    updatedObservations[i] = {
      ...observationObj,
      ...updatedObservations[i],
      [field]: value,
      selectedRefIndex: index,
    };

    setEditedObservations(updatedObservations);

    // Check if the content has been reverted to the original
    const isReverted =
      field === "observation"
        ? e.target.textContent === originalContent
        : field === "recommendations"
        ? e.target.textContent === originalContent
        : field === "area"
        ? e.value === originalContent
        : field === "criticality"
        ? e.value === originalContent
        : field === "is_reference"
        ? e.target.textContent === originalContent
        : field === "category"
        ? e.value === originalContent
        : false;

    // If content is reverted, disable editing and enable other rows
    if (isReverted) {
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setEditedObservations([]);
      setConfirmationShown(false); // Reset confirmation shown when content is reverted
    }

    // Update edited fields array based on the field edited
    switch (field) {
      case "category":
        updateEditedFields("CA");
        break;
      case "check_points":
        updateEditedFields("CH");
        break;
      case "observation":
        updateEditedFields("O");
        break;
      case "criticality":
        updateEditedFields("CR");
        break;
      case "recommendations":
        updateEditedFields("R");
        break;
      case "is_reference":
        updateEditedFields("I");
        break;
      // Add cases for other fields as needed
      default:
        break;
    }
  };

  const populateCategoryList = async (selectedAreas) => {
    try {
      // Filter the observations based on the selected areas
      const filteredObservations = allData.data.filter((item) =>
        selectedAreas.includes(item.area)
      );

      // Extract unique categories from the filtered observations
      const categories = [
        ...new Set(filteredObservations.map((item) => item.category)),
      ];

      setCategoryList(categories);
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const getCriticalObservations = async () => {
    try {
      const res = await axios.get(
        module === "cmv"
          ? `${config.PATH}/api/get-hse-cmv-critical-observations/${selectedReportData.report_id}`
          : `${config.PATH}/api/get-hse-critical-observations/${selectedReportData.report_id}`
      );
      setCriticalObservations(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    populateCategoryList(selectedArea); // Populate the category list based on selected areas
  }, [selectedArea]);

  useEffect(() => {
    getOrgList();
    populateAreaList();
  }, [allData, selectedCategory, selectedParam]);

  useEffect(() => {
    getSitesByOrganization(
      selectedOrganization ? selectedOrganization.value : null
    );
  }, [selectedOrganization, selectedSite]);

  useEffect(() => {
    getCriticalObservations();
    const updatedCriticalObservations = criticalObservations.filter((obs) => {
      return selectedObservations.some(
        (selectedObs) =>
          selectedObs.observation === obs.observation &&
          selectedObs.report_id === obs.report_id
      );
    });

    setCriticalObservations(updatedCriticalObservations);
  }, []);

  const refreshAllObservations = async () => {
    try {
      // Fetch the updated data from the API or wherever

      const endPoint =
        module === "cmv"
          ? `${config.PATH}/api/hse-cmv-report/${selectedReportData.report_id}`
          : `${config.PATH}/api/hse-report/${selectedReportData.report_id}`;
      const report = await axios.get(endDate);
      setAllObservations(report.data.AllObservations);
    } catch (error) {
      console.error("Error refreshing AllObservations:", error);
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderBottom: "none",
      boxShadow: "none",
      cursor: "pointer",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const handleDateTime = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const handleOrganizationSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidOrganizationName = /^[a-zA-Z0-9_-]+$/.test(
        selectedOption.label
      );

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidOrganizationName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidOrganizationName) {
          errorMessage =
            "Invalid organization name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Organization name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        org_name: selectedOption.label,
      };
      try {
        const response = await axios.post(
          `${config.PATH}/api/create-hse-org`,
          payload
        );
        const newOrganization = response.data; // Assuming the API returns the newly created organization object
        setSelectedOrganization({
          label: newOrganization.org_name,
          value: newOrganization.id,
        });
        setSelectedSite(null);
      } catch (error) {
        console.log("Failed to create organization:", error);
        return;
      }
    } else {
      setSelectedOrganization(selectedOption);
      setSelectedSite(null);
    }
  };

  const getSitesByOrganization = async (orgId) => {
    try {
      const response = await axios.get(
        `${config.PATH}/api/hse-organizations/${orgId}/sites`
      );
      const siteOptions = response.data.map((site) => ({
        label: site.site_name,
        value: site.site_name,
      }));
      setSiteOptions(siteOptions);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const getOrgList = async () => {
    try {
      const response = await axios.get(`${config.PATH}/api/get-hse-orgs`);
      setOrgList(response.data);
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const handleSiteSelection = async (selectedOption) => {
    if (selectedOption && selectedOption.__isNew__ === true) {
      const isValidSiteName = /^[a-zA-Z0-9_-]+$/.test(selectedOption.label);

      // Check if the length exceeds 40 characters

      const exceedsCharacterLimit = selectedOption.label.length > 40;

      if (!isValidSiteName || exceedsCharacterLimit) {
        let errorMessage = "";
        if (!isValidSiteName) {
          errorMessage =
            "Invalid Site name. Only hyphens ( - ), underscores ( _ ) and alphanumeric characters are allowed.";
        } else {
          errorMessage = `Site name cannot exceed 40 characters. You have typed ${selectedOption.label.length} characters.`;
        }
        alert(errorMessage);
        return;
      }

      const payload = {
        site_name: selectedOption.label,
        org_id: selectedOrganization ? selectedOrganization.value : null,
      };
      try {
        await axios.post(`${config.PATH}/api/create-hse-site`, payload);
      } catch (error) {
        console.log("Failed to create site:", error);
        return;
      }
    }
    setSelectedSite(selectedOption);
  };

  // const populateAreaList = async () => {
  //   try {
  //     const area = allData.data.map((e) => e.area);
  //     let uniqueAreaList = [...new Set(area)];
  //     setAreaList(uniqueAreaList);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const populateAreaList = async () => {
    try {
      // Check if selectedSector is an object with a valid value property
      if (selectedSector) {
        // Extract values from selectedParam if it contains any
        const selectedParamValues = selectedParam.map((param) => param.value);

        // Filter and get unique areas based on selectedParam values
        const area = allData.data
          .filter((e) => selectedParamValues.includes(e.table_type))
          .map((e) => e.area);

        let uniqueAreaList = [...new Set(area)];
        setAreaList(uniqueAreaList);
      } else {
        // Handle the case where selectedSector is null, undefined, or does not have a value
        setAreaList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const handleChangeArea = (areas) => {
  //   try {
  //     const selectedArea = areas.map((e) => e.value);
  //     setSelectedArea(selectedArea);

  //     function getCategoriesByAreas(areas) {
  //       const categories = [];
  //       for (const item of allData.data) {
  //         if (selectedArea.includes(item.area)) {
  //           categories.push(item.category);
  //         }
  //       }
  //       setCategoryList(categories);
  //     }

  //     if (areas.length === 0) {
  //       // If the 'areas' array is empty, set 'categories' to an empty array.
  //       setSelectedCategory([])
  //       setAllObservations([])
  //       setSelectedObservations([])
  //     } else {
  //       getCategoriesByAreas(areas);
  //     }
  //   } catch (error) {
  //     console.log("An error occurred:", error);
  //   }
  // };

  // const handleChangeArea = (areas) => {
  //   try {
  //     // setAreasToDisplay(areas);
  //     const selectedArea = areas.map((e) => e.value);
  //     setSelectedArea(selectedArea);

  //     function getCategoriesByAreas(areas) {
  //       const categories = [];
  //       for (const item of allData.data) {
  //         if (selectedArea.includes(item.area)) {
  //           categories.push(item.category);
  //         }
  //       }
  //       setCategoryList(categories);
  //     }

  //     if (areas.length === 0) {
  //       // If the 'areas' array is empty, set 'categories' and 'categoriesToDisplay' to empty arrays.
  //       setSelectedCategory([]);
  //       // setCategoriesToDisplay([]);
  //       setSelectedObservations([]);
  //       // Update the observations based on the empty category list
  //       handleChangeCategory([]);
  //       setCategoryList([]);
  //     } else {
  //       // Remove categories that are not associated with the selected areas.
  //       const updatedSelectedCategory = selectedCategory.filter((category) => {
  //         const categoryArea = allData.data.find(
  //           (item) => item.category === category
  //         )?.area;
  //         return selectedArea.includes(categoryArea);
  //       });
  //       setSelectedCategory(updatedSelectedCategory);
  //       getCategoriesByAreas(areas);

  //       // Set 'categoriesToDisplay' to the categories associated with the selected areas.
  //       const updatedCategoriesToDisplay = categoryList
  //         .filter((category) => updatedSelectedCategory.includes(category))
  //         .map((category) => ({ label: category, value: category }));

  //       const uniqueCategories = [
  //         ...new Set(updatedCategoriesToDisplay.map(JSON.stringify)),
  //       ].map(JSON.parse);
  //       // setCategoriesToDisplay(uniqueCategories);
  //       // Update the observations based on the updated selected categories
  //       handleChangeCategory(uniqueCategories);
  //     }
  //   } catch (error) {
  //     console.log("An error occurred:", error);
  //   }
  // };
  const handleChangeArea = (areas) => {
    try {
      const selectedArea = areas.map((e) => e.value);
      setSelectedArea(selectedArea);

      // If areas are removed, filter out observations that are no longer in the selected areas
      const updatedSelectedObservations = selectedObservations.filter(
        (observation) => selectedArea.includes(observation.area)
      );

      // Filter observations based on the updated selected areas
      const filteredObservations = allData.data
        .concat(selectedReportData.AllObservations)
        .filter((observation) => selectedArea.includes(observation.area))
        .filter((observation, index, self) => {
          const observationValue = observation.observation;

          // Ensure each observation is unique
          return (
            index ===
            self.findIndex((obs) => obs.observation === observationValue)
          );
        })
        .map((observation) => {
          const selectedObs = updatedSelectedObservations.find(
            (selectedObs) => selectedObs.observation === observation.observation
          );
          if (selectedObs) {
            return {
              ...observation,
              is_selected: 1,
              score: selectedObs.score,
              imageUrls: selectedObs.imageUrls,
            };
          }
          return observation;
        });

      setAllObservations(filteredObservations);
      setSelectedObservations(
        filteredObservations.filter((e) => e.is_selected === 1)
      );

      // Handle the case when no areas are selected
      if (areas.length === 0) {
        setSelectedObservations([]);
        setAllObservations([]);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const handleChangeCategory = async (cat) => {
    const selectedCat = cat.map((e) => e.value);
    setSelectedCategory(selectedCat);

    try {
      const filteredObservations = allData.data
        .concat(selectedReportData.AllObservations)
        .filter(
          (observation) =>
            selectedCat.includes(observation.category) &&
            selectedArea.includes(observation.area)
        )
        .filter((observation, index, self) => {
          const observationValue = observation.observation;

          // Check if the observationValue is unique in the array
          return (
            index ===
            self.findIndex((obs) => obs.observation === observationValue)
          );
        })
        .map((observation) => {
          const selectedObs = selectedObservations.find(
            (selectedObs) => selectedObs.observation === observation.observation
          );
          if (selectedObs) {
            return {
              ...observation,
              is_selected: 1,
              score: selectedObs.score,
              imageUrls: selectedObs.imageUrls,
            };
          }
          return observation;
        });

      setAllObservations(filteredObservations);
      setSelectedObservations(
        filteredObservations.filter((e) => e.is_selected === 1)
      );
      // Check if any observations in selectedObservations are related to removed categories
      const removedCategories = selectedObservations.filter(
        (selectedObs) => !selectedCat.includes(selectedObs.category)
      );
      if (removedCategories.length > 0) {
        // Uncheck these observations
        const updatedSelectedObservations = selectedObservations.filter(
          (selectedObs) =>
            !removedCategories.some(
              (removedCat) => removedCat.sr_no === selectedObs.sr_no
            )
        );
        setSelectedObservations(updatedSelectedObservations);
      }
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  // const handleChangeCategory = async (cat) => {
  //   const selectedCat = cat.map((e) => e.value);
  //   setSelectedCategory(selectedCat);

  //   try {
  //     // Filter the observations from allData based on selected categories
  //     const filteredObservations = allData.data.filter((observation) => {
  //       return selectedCat.includes(observation.category);
  //     });

  //     // Update the filtered observations with the is_selected flag from the current state
  //     const updatedObservations = filteredObservations.map((filteredObs) => {
  //       const selectedObs = selectedObservations.find(
  //         (selectedObs) => selectedObs.sr_no === filteredObs.sr_no
  //       );
  //       if (selectedObs) {
  //         return { ...filteredObs, is_selected: 1 };
  //       }
  //       return filteredObs;
  //     });

  //     setAllObservations(updatedObservations);

  //     // Check if any observations in selectedObservations are related to removed categories
  //     const removedCategories = selectedObservations.filter(
  //       (selectedObs) => !selectedCat.includes(selectedObs.category)
  //     );
  //     if (removedCategories.length > 0) {
  //       // Uncheck these observations
  //       const updatedSelectedObservations = selectedObservations.filter(
  //         (selectedObs) =>
  //           !removedCategories.some(
  //             (removedCat) => removedCat.sr_no === selectedObs.sr_no
  //           )
  //       );
  //       setSelectedObservations(updatedSelectedObservations);
  //     }
  //   } catch (error) {
  //     console.log("Error:", error.response?.data?.error || error.message);
  //   }
  // };

  // This is to add refIndex in selected observation when component loads
  // useEffect(() => {
  //   // Update refIndex for initially selected observations
  //   setSelectedObservations((prevSelectedObservations) =>
  //     prevSelectedObservations.map((obs) => {
  //       const indexInAllObservations = AllObservations.findIndex(
  //         (allObs) => allObs.id === obs.id
  //       );
  //       return {
  //         ...obs,
  //         refIndex: indexInAllObservations,
  //       };
  //     })
  //   );
  // }, [AllObservations]);

  useEffect(() => {
    // Update refIndex for initially selected observations
    setSelectedObservations((prevSelectedObservations) =>
      prevSelectedObservations.map((obs) => {
        let indexInAllObservations;
        if (obs.id) {
          indexInAllObservations = AllObservations.findIndex(
            (allObs) => allObs.id === obs.id
          );
        } else if (obs.sr_no) {
          indexInAllObservations = AllObservations.findIndex(
            (allObs) => allObs.sr_no === obs.sr_no
          );
        }
        return {
          ...obs,
          refIndex:
            indexInAllObservations !== -1 ? indexInAllObservations : null,
        };
      })
    );
  }, [AllObservations]);

  const handleObservationSelection = (observation, index) => {
    const tempObs = [...AllObservations];

    let isAlreadySelected = observation.is_selected;

    if (isAlreadySelected) {
      delete tempObs[index].is_selected;
      setSelectedObservations(
        [...selectedObservations].filter(
          (e) =>
            `${e.sr_no || e.id}` !== `${observation.sr_no || observation.id}` &&
            e.observation !== observation.observation
        )
      );
      setCriticalObservations(
        [...selectedObservations].filter(
          (e) =>
            e.criticality === "High" &&
            `${e.sr_no || e.id}` !== `${observation.sr_no || observation.id}` &&
            e.observation !== observation.observation
        )
      );
    } else {
      tempObs[index] = { ...observation, is_selected: 1 };
      setSelectedObservations([
        ...selectedObservations,
        { ...observation, refIndex: index },
      ]);
      setCriticalObservations(
        [...selectedObservations, observation].filter(
          (e) => e.criticality === "High"
        )
      );
    }
    setAllObservations(tempObs);
  };

  const handleProceed = async () => {
    try {
      // setLoading(true)
      // Make API call with selectedObservations as input
      const payload = {
        observations: selectedObservations.map((e) => e.observation),
        // observations: selectedObservations.map((e) => e.row_id),
        report_id: selectedReportData.report_id,
      };

      // let res = await axios.post(
      //   `${config.PATH}/api/hse-recommendations`,
      //   payload
      // );
      // const receivedRecommendations = res.data.data;
      // setRecommendations(res.data.data);
      const reportData = {
        report_id: selectedReportData.report_id,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        image_urls: JSON.stringify(imageUrlsByRow),
        is_edited: isReportEdited,
        start_date: startDate,
        end_date: endDate,
        is_saved: true,
      };
      if (module === "cmv") {
        reportData.type = "cmv";
      } else {
        reportData.type = "primary";
      }
      handleNext();

      const cmvEndPOint = `${config.PATH}/api/save-update-hse-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-hse-report`;

      if (module === "cmv") {
        await axios.post(cmvEndPOint, reportData);
      } else {
        await axios.post(reportEndPoint, reportData);
      }

      const observationsData = {
        report_id: selectedReportData.report_id,
        // observation: selectedObservations,
        // critical_observations: criticalObservations,
        all_observations: AllObservations,
        organization: selectedOrganization.label,
        site: selectedSite.label,
        user_id: userId,
      };

      const observationEndPoint = `${config.PATH}/api/save-update-hse-observations`;
      const cmvObservationEndPoint = `${config.PATH}/api/save-update-hse-cmv-observations`;

      if (module === "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
      } else {
        await axios.post(observationEndPoint, observationsData);
      }

      // const recommendationsData = {
      //   report_id: selectedReportData.report_id,
      //   recommendations: receivedRecommendations,
      // };
      // await axios.post(
      //   `${config.PATH}/api/save-update-hse-recommendations`,
      //   recommendationsData
      // );
      // setLoading(false)
    } catch (error) {
      console.log("Error:", error.response?.data?.error || error.message);
    }
  };

  const keyNotToCheck = [
    "remarks",
    "score",
    "refIndex",
    "selectedRefIndex",
    "equipment",
    "is_selected",
    "imageUrls",
    "edited_fields",
    "status",
  ];

  const updateOrgReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      axios.post(`${config.PATH}/api/update-org-report-status/${org_id}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateSiteReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      const site_name = selectedSite.value;
      axios.post(
        `${config.PATH}/api/update-site-report-status/${org_id}/${site_name}`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleComplete = async () => {
    if (!isSaved) {
      toast.warning("Please save the report before marking as complete.");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to mark this report as complete? Once done action cannot be reversed and you cannot edit the report.`
    );

    if (!userConfirmed) {
      return;
    }
    setIsComplete(true);
    handleSave(true);
    closeReport();
    setOpenReportList(false);
    getAllHseReports();
  };

  const handleSave = async (complete) => {
    try {
      setLoading(true);
      let updatedSelectedObservations = [...selectedObservations];
      let updatedAllObservations = [...AllObservations];

      if (editedObservations[0]) {
        if (
          Object.entries(editedObservations[0]).filter(
            (e) => !(keyNotToCheck.includes(e[0]) || e[1])
          ).length > 0
        ) {
          toast.error("Table fields can't be empty.");
          setLoading(false);
          return;
        }

        // if (
        //   selectedObservations.filter(
        //     (obj) => obj.observation === editedObservations[0].observation
        //   ).length > 0
        // ) {
        //   toast.error(
        //     "Duplicate observations detected. Please modify observations to ensure uniqueness."
        //   );
        //   setLoading(false);
        //   return;
        // }

        editedObservations.forEach((obj) => {
          updatedSelectedObservations[obj.selectedRefIndex] = obj;
          updatedAllObservations[obj.refIndex] = { ...obj, is_selected: 1 };
        });

        // if (isReportEdited) {
        //   const payload = editedObservations
        //     // .filter(({ note_type }) => !note_type)
        //     .map(({ sr_no, score, ...rest }) => rest);

        //   if (payload.length > 0) {
        //     await axios.post(`${config.PATH}/api/insert-new-hse-row`, payload);
        //   }
        // } else {
        //   //pass
        // }

        if (isReportEdited) {
          const payload = editedObservations
            .map(({ sr_no, score, edited_fields, ...rest }) => ({
              ...rest,
              edited_fields: editedFields, // Include edited_fields in the payload
            }))
            .filter((item) => Object.keys(item).length > 0); // Filter out items with no properties

          if (payload.length > 0) {
            await axios.post(`${config.PATH}/api/insert-new-hse-row`, payload);
          }
        } else {
          //pass
        }

        setSelectedObservations(updatedSelectedObservations);
        setAllObservations(updatedAllObservations);
      }

      const reportData = {
        report_id: selectedReportData.report_id,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        image_urls: JSON.stringify(imageUrlsByRow),
        is_edited: isReportEdited,
        is_complete: complete === true ? true : false,
        start_date: startDate,
        end_date: endDate,
        is_saved: true,
      };
      if (module === "cmv") {
        reportData.type = "cmv";
      } else {
        reportData.type = "primary";
      }

      const cmvEndPOint = `${config.PATH}/api/save-update-hse-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-hse-report`;

      if (module === "cmv") {
        await axios.post(cmvEndPOint, reportData);
      } else if (complete === true && module !== "cmv") {
        await axios.post(cmvEndPOint, reportData);
        await axios.post(reportEndPoint, reportData);
      } else {
        await axios.post(reportEndPoint, reportData);
      }

      const observationsData = {
        report_id: selectedReportData.report_id,
        all_observations: updatedAllObservations,
        organization: selectedOrganization.label,
        site: selectedSite.label,
        user_id: userId,
      };

      // const observationEndpoint =
      //   complete === true
      //     ? `${config.PATH}/api/save-update-hse-cmv-observations`
      //     : `${config.PATH}/api/save-update-hse-observations`;

      // await axios.post(observationEndpoint, observationsData);

      const observationEndPoint = `${config.PATH}/api/save-update-hse-observations`;
      const cmvObservationEndPoint = `${config.PATH}/api/save-update-hse-cmv-observations`;

      if (module === "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
      } else if (complete === true && module !== "cmv") {
        await axios.post(cmvObservationEndPoint, observationsData);
        await axios.post(observationEndPoint, observationsData);
      } else {
        await axios.post(observationEndPoint, observationsData);
      }

      setSelectedObservations(updatedSelectedObservations);
      setAllObservations(updatedAllObservations);
      setIsSaved(true);
      setEditedObservations([]);
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setDisableSaveNext(false);
      refreshAllObservations();
      getAllHseData();
      saveCriticalObservations(complete);
      saveFacilityInfo();
      setLoading(false);
      updateOrgReportStatus();
      updateSiteReportStatus();
      setEditedFields([]);
      setConfirmationShown(false);
      toast.success(
        `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const saveCriticalObservations = async (complete) => {
    try {
      // Create a new array with objects excluding the 'id' field
      const observationsToSave = criticalObservations.map(
        ({ id, ...observation }) => observation
      );

      // if (observationsToSave.length === 0) {
      //   console.log("No observations to save.");
      //   return;
      // }

      const payload = {
        criticalObservations: observationsToSave,
        report_id: selectedReportData.report_id,
      };

      //   const endpoint = complete===true
      // ? `${config.PATH}/api/save-critical-hse-cmv-observations`
      // : `${config.PATH}/api/save-hse-critical-observations`;

      //   await axios.post(endpoint,payload);

      const criticalObsEndPoint = `${config.PATH}/api/save-hse-critical-observations`;
      const cmvCriticalObsEndPoint = `${config.PATH}/api/save-critical-hse-cmv-observations`;

      if (module === "cmv") {
        await axios.post(cmvCriticalObsEndPoint, payload);
      } else if (complete === true && module !== "cmv") {
        await axios.post(cmvCriticalObsEndPoint, payload);
        await axios.post(criticalObsEndPoint, payload);
      } else {
        await axios.post(criticalObsEndPoint, payload);
      }

      console.log("Critical observations saved successfully.");
    } catch (error) {
      console.error("Error saving critical observations:", error.message);
    }
  };

  const handleChange = (e, name) => {
    setIsSaved(false);
    if (name === "background") {
      setBackgroundBrief(e.target.value);
    } else if (name === "contents") {
      setContents(e.target.value);
    } else if (name === "exe") {
      setExeSummary(e.target.value);
    } else if (name === "conclusion") {
      setConclusion(e.target.value);
    } else if (name === "other details") {
      setOtherDetails(e.target.value);
    } else if (name === "best_practice") {
      setbestPractice(e.target.value);
    } else if (name === "the_way_forward") {
      setTheWayForward(e.target.value);
    } else if (name === "intro") {
      setIntroduction(e.target.value);
    }
  };

  const handleNext = () => {
    if (isEditing) {
      toast.warning("Please save changes before proceeding.");
      return;
    }
    setScreenNumber(screenNumber + 1);
    // setOpenReportList(false);
  };

  const handlePrev = () => {
    if (isEditing) {
      toast.warning("Please save changes before going back.");
      return;
    }
    setScreenNumber(screenNumber - 1);
  };

  const removeItem = (index) => {
    const updatedObservations = [...criticalObservations];
    updatedObservations.splice(index, 1);
    setCriticalObservations(updatedObservations);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const MAX_WIDTH = 800; // Maximum width for the compressed image
        const MAX_HEIGHT = 800; // Maximum height for the compressed image

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.7); // 0.7 is the image quality (0.0 - 1.0)
      };

      img.src = URL.createObjectURL(file);
    });
  };

  // const handleImageUpload = async (index, files) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...AllObservations];

  //   if (!selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls = [];
  //   }

  //   try {
  //     setLoading(true);
  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image `,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;
  //       selectedObsCopy[index].imageUrls.push(imageUrl);
  //       // imageUrlsByRow[index].push(imageUrl);
  //     }

  //     selectedObsCopy.forEach((e) => {
  //       obsCopy[e.refIndex] = {
  //         ...obsCopy[e.refIndex],
  //         imageUrls: e.imageUrls,
  //       };
  //     });

  //     // setImageUrlsByRow({ ...imageUrlsByRow });
  //     setSelectedObservations(selectedObsCopy);
  //     setAllObservations(obsCopy);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  // const handleImageUpload = async (index, files) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...AllObservations];
  //   const editedObsCopy = [...editedObservations];

  //   if (!selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls = [];
  //   }

  //   try {
  //     setLoading(true);

  //     for (const file of Array.from(files)) {
  //       if (!file.type.startsWith("image/")) {
  //         throw new Error("Only image files are allowed.");
  //       }

  //       const compressedImage = await compressImage(file);

  //       const formData = new FormData();
  //       formData.append("image", compressedImage);

  //       const response = await axios.post(
  //         `${config.PATH}/api/upload/image `,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const imageUrl = response.data.imageUrl;
  //       selectedObsCopy[index].imageUrls.push(imageUrl);

  //       // Update editedObservations if it has values
  //       if (editedObsCopy.length > 0) {
  //         const editedIndex = editedObsCopy.findIndex(
  //           (observation) =>
  //             observation.refIndex === selectedObsCopy[index].refIndex
  //         );
  //         if (editedIndex !== -1) {
  //           editedObsCopy[editedIndex].imageUrls =
  //             selectedObsCopy[index].imageUrls;
  //         }
  //       }
  //     }

  //     selectedObsCopy.forEach((e) => {
  //       obsCopy[e.refIndex] = {
  //         ...obsCopy[e.refIndex],
  //         imageUrls: e.imageUrls,
  //       };
  //     });

  //     setSelectedObservations(selectedObsCopy);
  //     setAllObservations(obsCopy);

  //     // Update editedObservations only if it has values
  //     if (editedObsCopy.length > 0) {
  //       setEditedObservations(editedObsCopy);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     console.log("Error uploading image:", error);
  //     toast.error(error.message);
  //   }
  // };

  const handleImageUpload = async (table_type, identifier, area, files) => {
    // Create deep copies to avoid mutation
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...AllObservations];
    const editedObsCopy = [...editedObservations];

    // Ensure the correct structure for deep copying
    selectedObsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });

    obsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });

    editedObsCopy.forEach((item) => {
      if (!item.imageUrls) item.imageUrls = [];
    });

    try {
      setLoading(true);

      for (const file of Array.from(files)) {
        if (!file.type.startsWith("image/")) {
          throw new Error("Only image files are allowed.");
        }

        const compressedImage = await compressImage(file);

        const formData = new FormData();
        formData.append("image", compressedImage);

        const response = await axios.post(
          `${config.PATH}/api/upload/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data.imageUrl;

        // Update the imageUrls in selectedObservations
        const selectedObs = selectedObsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (selectedObs) {
          selectedObs.imageUrls = [...(selectedObs.imageUrls || []), imageUrl];
        }

        // Update the imageUrls in AllObservations
        const obs = obsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (obs) {
          obs.imageUrls = [...(obs.imageUrls || []), imageUrl];
        }

        // Update editedObservations if it has values
        const editedObs = editedObsCopy.find(
          (item) =>
            item.table_type === table_type &&
            (item.sr_no === identifier || item.id === identifier) &&
            item.area === area
        );
        if (editedObs) {
          editedObs.imageUrls = [...(editedObs.imageUrls || []), imageUrl];
        }
      }

      setSelectedObservations(selectedObsCopy);
      setAllObservations(obsCopy);

      // Update editedObservations only if it has values
      if (editedObsCopy.length > 0) {
        setEditedObservations(editedObsCopy);
      }

      setLoading(false);
    } catch (error) {
      console.log("Error uploading image:", error);
      toast.error(error.message);
    }
  };

  const handleRemoveImage = (table_type, area, identifier, imageIndex) => {
    // Create deep copies to avoid mutation
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...AllObservations];

    // Find the observation with the matching table_type, area, and identifier
    const observationToUpdate = selectedObsCopy.find(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (observationToUpdate && observationToUpdate.imageUrls) {
      // Remove the image URL at the specified index
      observationToUpdate.imageUrls.splice(imageIndex, 1);

      // If there are no more images in the array, remove the imageUrls property
      if (observationToUpdate.imageUrls.length === 0) {
        delete observationToUpdate.imageUrls;
      }

      // Update the corresponding observation in obsCopy
      const obsIndex = obsCopy.findIndex(
        (obs) =>
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
      );

      if (obsIndex !== -1) {
        obsCopy[obsIndex] = {
          ...obsCopy[obsIndex],
          imageUrls: observationToUpdate.imageUrls,
        };
      }

      // Update state
      setSelectedObservations(selectedObsCopy);
      setAllObservations(obsCopy);
    } else {
      toast.error("Observation not found.");
    }
  };

  // const handleRemoveImage = (index, imageIndex) => {
  //   const selectedObsCopy = [...selectedObservations];
  //   const obsCopy = [...AllObservations];

  //   if (selectedObsCopy[index].imageUrls) {
  //     selectedObsCopy[index].imageUrls.splice(imageIndex, 1);

  //     // If there are no more images in the array, remove the property
  //     if (selectedObsCopy[index].imageUrls.length === 0) {
  //       delete selectedObsCopy[index].imageUrls;
  //     }

  //     // Update the corresponding observation in obsCopy
  //     obsCopy[selectedObsCopy[index].refIndex] = {
  //       ...obsCopy[selectedObsCopy[index].refIndex],
  //       imageUrls: selectedObsCopy[index].imageUrls,
  //     };

  //     // Update state
  //     setSelectedObservations(selectedObsCopy);
  //     setAllObservations(obsCopy);
  //   }
  // };

  const handleClose = () => {
    if (isEditing && screenNumber === 8) {
      toast.warning("Please save changes before closing the report.");
      return;
    } else if (!isSaved && screenNumber !== 1 && !exp) {
      toast.warning("Please save the report before closing.");
    } else {
      closeReport();
    }
  };

  const closeReport = () => {
    setOpenSavedReport(false);
  };

  const handleScoreChange = (event, tableType, sr_no) => {
    setIsSaved(false);
    const newScore = parseInt(event.target.value, 10);
  
    // Validate the new score
    if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
      // Clone the observations and selectedObservations arrays
      const newObservations = [...AllObservations];
      const newSelectedObservations = [...selectedObservations];
  
      // Find the relevant observation in observations using either sr_no or id
      const globalIndex = newObservations.findIndex(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );
  
      if (globalIndex !== -1) {
        newObservations[globalIndex].score =
          event.target.value === "" ? null : newScore;
      }
  
      // Find the relevant observation in selectedObservations using either sr_no or id
      const selectedIndex = newSelectedObservations.findIndex(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );
  
      if (selectedIndex !== -1) {
        newSelectedObservations[selectedIndex].score =
          event.target.value === "" ? null : newScore;
      }
  
      // Update the state with the modified arrays
      setAllObservations(newObservations);
      setSelectedObservations(newSelectedObservations);
    } else {
      alert("Invalid score. Please enter a value between 0 and 5.");
      // Find the observation to restore the original score in the input field
      const originalObservation = AllObservations.find(
        (obs) => obs.table_type === tableType && (obs.sr_no === sr_no || obs.id === sr_no)
      );
      event.target.value = originalObservation ? originalObservation.score : "";
    }
  };  

  // const handleScoreChange = (event, tableType, sr_no) => {
  //   setIsSaved(false);
  //   const newScore = parseInt(event.target.value, 10);

  //   // Validate the new score
  //   if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
  //     // Clone the observations and selectedObservations arrays
  //     const newObservations = [...AllObservations];
  //     const newSelectedObservations = [...selectedObservations];

  //     // Find the relevant observation in observations using sr_no and tableType
  //     const globalIndex = newObservations.findIndex(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );

  //     if (globalIndex !== -1) {
  //       newObservations[globalIndex].score =
  //         event.target.value === "" ? null : newScore;
  //     }

  //     // Find the relevant observation in selectedObservations using sr_no and tableType
  //     const selectedIndex = newSelectedObservations.findIndex(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );

  //     if (selectedIndex !== -1) {
  //       newSelectedObservations[selectedIndex].score =
  //         event.target.value === "" ? null : newScore;
  //     }

  //     // Update the state with the modified arrays
  //     setAllObservations(newObservations);
  //     setSelectedObservations(newSelectedObservations);
  //   } else {
  //     alert("Invalid score. Please enter a value between 0 and 5.");
  //     // Find the observation to restore the original score in the input field
  //     const originalObservation = AllObservations.find(
  //       (obs) => obs.table_type === tableType && obs.sr_no === sr_no
  //     );
  //     event.target.value = originalObservation ? originalObservation.score : "";
  //   }
  // };

  // const handleScoreChange = (event, index) => {
  //   setIsSaved(false);
  //   const newScore = parseInt(event.target.value, 10);

  //   if ((newScore >= 0 && newScore <= 5) || event.target.value === "") {
  //     const newObservations = [...AllObservations];
  //     const newSelectedObservations = [...selectedObservations];

  //     newObservations[index].score =
  //       event.target.value === "" ? null : newScore;
  //     newSelectedObservations[index].score =
  //       event.target.value === "" ? null : newScore;

  //     setAllObservations(newObservations);
  //     setSelectedObservations(newSelectedObservations);
  //   } else {
  //     alert("Invalid score. Please enter a value between 0 and 5.");
  //     event.target.value = AllObservations[index].score || "";
  //   }
  // };

  const handleDuplicateRow = (table_type, area, identifier) => {
    if (isEditing) {
      toast.warn(
        "Please finish editing the current row before adding a new row."
      );
      return;
    }

    // Find the row to duplicate
    const selectedOriginalRow = selectedObservations.find(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (!selectedOriginalRow) {
      toast.error("Observation not found.");
      return;
    }

    // Create a duplicated row
    const duplicatedRowForSelected = {
      ...selectedOriginalRow,
      refIndex: selectedOriginalRow.refIndex + 1, // Adjust refIndex or handle it based on your requirement
      imageUrls: selectedOriginalRow.imageUrls
        ? [...selectedOriginalRow.imageUrls]
        : [],
    };

    // Create copies of state arrays
    const updatedAllObservations = [...AllObservations];
    const updatedSelectedObservations = [...selectedObservations];

    // Insert duplicated row into observations and selectedObservations
    updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
      ...duplicatedRowForSelected,
      is_selected: 1,
    });
    updatedSelectedObservations.splice(
      updatedSelectedObservations.findIndex(
        (obs) =>
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
      ) + 1,
      0,
      duplicatedRowForSelected
    );

    // Update state
    setAllObservations(updatedAllObservations);
    setSelectedObservations(updatedSelectedObservations);

    toast.success("New row added");
  };

  // const handleDuplicateRow = (index) => {
  //   if (isEditing) {
  //     toast.warn(
  //       "Please finish editing the current row before adding a new row."
  //     );
  //     return;
  //   }

  //   const selectedOriginalRow = selectedObservations[index];

  //   // Check if imageUrls is defined, and create a deep copy if it is, or an empty array otherwise
  //   const duplicatedRowForSelected = {
  //     ...selectedOriginalRow,
  //     refIndex: selectedOriginalRow.refIndex + 1,
  //     imageUrls: selectedOriginalRow.imageUrls
  //       ? [...selectedOriginalRow.imageUrls]
  //       : [],
  //   };

  //   const updatedAllObservations = [...AllObservations];
  //   const updatedSelectedObservations = [...selectedObservations];

  //   updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
  //     ...duplicatedRowForSelected,
  //     is_selected: 1,
  //   });
  //   updatedSelectedObservations.splice(index + 1, 0, duplicatedRowForSelected);

  //   setAllObservations(updatedAllObservations);
  //   setSelectedObservations(updatedSelectedObservations);

  //   toast.success("New row added");
  // };

  // const handleDeleteRow = (index) => {
  //   if (isEditing && currentEditedRow !== index) {
  //     toast.warn(
  //       "Please finish editing the current row before deleting the row."
  //     );
  //     return;
  //   }
  //   const updatedSelectedObservations = [...selectedObservations];
  //   const updatedAllObservations = [...AllObservations];

  //   // Check if there is only one row left
  //   if (updatedSelectedObservations.length === 1) {
  //     toast.warn("Cannot delete the last row.");
  //     return;
  //   }

  //   const refIndex = selectedObservations[index].refIndex;

  //   updatedSelectedObservations.splice(index, 1);
  //   updatedAllObservations.splice(refIndex, 1);
  //   updatedSelectedObservations.forEach(
  //     (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
  //   );
  //   setSelectedObservations(updatedSelectedObservations);
  //   setAllObservations(updatedAllObservations);
  //   setIsEditing(false);
  //   setEditedObservations([]);
  //   setCurrentEditedRow(-1);
  //   toast.error("Row deleted");
  // };

  const handleDeleteRow = (table_type, area, identifier) => {
    console.log(table_type, area, identifier);
    if (isEditing && currentEditedRow !== -1) {
      toast.warn(
        "Please finish editing the current row before deleting the row."
      );
      return;
    }

    // Create deep copies to avoid mutation
    const updatedSelectedObservations = [...selectedObservations];
    let updatedAllObservations = [...AllObservations]; // Use let instead of const

    // Check if there is only one row left
    if (updatedSelectedObservations.length === 1) {
      toast.warn("Cannot delete the last row.");
      return;
    }

    // Find the index to delete using table_type, area, and identifier (sr_no or id)
    const rowIndex = updatedSelectedObservations.findIndex(
      (obs) =>
        obs.table_type === table_type &&
        obs.area === area &&
        (obs.sr_no === identifier || obs.id === identifier)
    );

    if (rowIndex === -1) {
      toast.error("Row not found.");
      return;
    }

    // Find the observation to delete
    const observationToDelete = updatedSelectedObservations[rowIndex];
    const refIndex = observationToDelete.refIndex;

    // Remove the observation from both arrays
    updatedSelectedObservations.splice(rowIndex, 1);
    updatedAllObservations = updatedAllObservations.filter(
      (obs) =>
        !(
          obs.table_type === table_type &&
          obs.area === area &&
          (obs.sr_no === identifier || obs.id === identifier)
        )
    );

    // Adjust refIndex for the remaining observations
    updatedSelectedObservations.forEach(
      (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
    );

    setSelectedObservations(updatedSelectedObservations);
    setAllObservations(updatedAllObservations);
    setIsEditing(false);
    setEditedObservations([]);
    setCurrentEditedRow(-1);
    toast.error("Row deleted");
  };

  const sectors = () => {
    try {
      const options = [];
      const list = [
        ...new Set(
          allData.data
            .map((e) => e.sector_type)
            .filter(
              (sector_type) =>
                sector_type !== null &&
                sector_type !== undefined &&
                sector_type !== ""
            )
        ),
      ];
      list.map((area) => options.push({ label: area, value: area }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeSector = (e) => {
    if (!e) {
      setSelectedSector([]);
      setSelectedParam([]);
      setSelectedArea([]);
      setAreaList([]);
    }
    setSelectedSector(e);
  };

  const handleChangeParam = (e) => {
    if (!e || e.length === 0) {
      setSelectedArea([]);
      setAreaList([]);
    }
    setSelectedParam(e);
  };

  const getScoreColor = (percentage) => {
    if (percentage <= 33) {
      return "#FF0000"; // Red
    } else if (percentage > 33 && percentage <= 66) {
      return "#FFA500"; // Orange
    } else {
      return "#006400"; // Dark Green
    }
  };

  const pieOptions = {
    labels: [
      `Obtained Score(${scorePercent}%)`,
      `Remaining Score(${100 - scorePercent}%)`,
    ],
    colors: [getScoreColor(scorePercent), "grey"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 16,
        height: 16,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const areaCounts = data.reduce((counts, entry) => {
    const area = entry.area;
    counts[area] = (counts[area] || 0) + 1;
    return counts;
  }, {});

  const areasForAreaChart = Object.keys(areaCounts);
  const counts = areasForAreaChart.map((area) => {
    const areaScore = data
      .filter((entry) => entry.area === area)
      .reduce((acc, entry) => acc + (entry.score || 0), 0);
    const totalPossibleScore =
      data.filter((entry) => entry.area === area).length * 5;
    const percentage =
      totalPossibleScore > 0
        ? Math.floor((areaScore / totalPossibleScore) * 100)
        : 0;
    return percentage.toFixed(0);
  });

  const barOptions = {
    chart: {
      id: "bar-chart",
    },
    colors: ["#005cdb"],
    xaxis: {
      categories: areasForAreaChart.map((area) => {
        // Truncate the area name if it's too long
        const maxChars = 15; // Adjust the maximum characters as needed
        return area.length > maxChars
          ? area.substring(0, maxChars) + "..."
          : area;
      }),
      labels: {
        style: {
          fontSize: "10px", // Adjust the font size as needed
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    tooltip: {
      y: {
        formatter: function (value, { dataPointIndex }) {
          // Display the full area name on hover
          const area = areasForAreaChart[dataPointIndex];
          return `${area}: ${value}%`; // Show area name along with percentage
        },
      },
    },
  };
  // Group the data by area and severity
  const areaSeverityData = data.reduce((result, entry) => {
    const area = entry.area;
    const severity = entry.criticality;

    if (!result[area]) {
      result[area] = { High: 0, Medium: 0, Low: 0 };
    }

    result[area][severity] += 1;
    return result;
  }, {});

  // Extract areas and severity counts
  const areas = Object.keys(areaSeverityData);
  const severityChartData = Object.keys(areaSeverityData).map((area) => ({
    name: area,
    High: areaSeverityData[area].High,
    Medium: areaSeverityData[area].Medium,
    Low: areaSeverityData[area].Low,
  }));

  // Transpose the data to match the series structure
  const transposedData = {
    High: [],
    Medium: [],
    Low: [],
  };

  severityChartData.forEach((area) => {
    transposedData.High.push(area.High);
    transposedData.Medium.push(area.Medium);
    transposedData.Low.push(area.Low);
  });

  // Prepare series data with specific colors for each severity level
  const seriesData = [
    { name: "High", data: [] },
    { name: "Medium", data: [] },
    { name: "Low", data: [] },
  ];

  areas.forEach((area) => {
    seriesData[0].data.push(areaSeverityData[area]?.High || 0);
    seriesData[1].data.push(areaSeverityData[area]?.Medium || 0);
    seriesData[2].data.push(areaSeverityData[area]?.Low || 0);
  });

  // Severity chart options
  const severityChartOptions = {
    chart: {
      id: "severity-chart",
      stacked: true,
    },
    xaxis: {
      categories: areas.map((area) => {
        // Truncate the area name if it's too long
        const maxChars = 15; // Adjust the maximum characters as needed
        return area.length > maxChars
          ? area.substring(0, maxChars) + "..."
          : area;
      }),
      labels: {
        style: {
          fontSize: "10px", // Adjust the font size as needed
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "10px", // Adjust the font size for the total data label
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels
    },
    colors: ["#FF0000", "#006400", "#005cdb"],
    tooltip: {
      y: {
        formatter: function (value, { dataPointIndex }) {
          // Display the full area name on hover
          const area = areas[dataPointIndex];
          return `${area}:${value}`;
        },
      },
    },
  };

  const handleObservationEdit = (index, e) => {
    setDisableSaveNext(true);
    const updatedObservations = [...criticalObservations];
    updatedObservations[index].observation = e.target.value;
    setCriticalObservations(updatedObservations);
  };

  const handleStartEndDate = (date, name) => {
    if (!date) {
      // Handle case when date is null or undefined
      if (name === "start-date") {
        setStartDate(null);
      } else if (name === "end-date") {
        setEndDate(null);
      } else {
        if (startDate) setEndDate(null);
      }
      return;
    }

    // Ensure that the date part is set to midnight (00:00:00)
    const adjustedDate = new Date(date);
    adjustedDate.setHours(0, 0, 0, 0);

    if (name === "start-date") {
      if (endDate && adjustedDate > endDate) {
        toast.warning("Start date cannot be after the end date.");
        setStartDate(null);
        return;
      }
      setStartDate(adjustedDate);
    } else {
      if (startDate && adjustedDate < startDate) {
        toast.warning("End date cannot be before the start date.");
        setEndDate(null);
        return;
      }
      setEndDate(adjustedDate);
    }
  };

  // const uniqueCategories = new Set();
  // const categoryOptions = [];

  // allData.data.forEach((e) => {
  //   if (!uniqueCategories.has(e.category)) {
  //     categoryOptions.push({ value: e.category, label: e.category });
  //     uniqueCategories.add(e.category);
  //   }
  // });

  useEffect(() => {
    const uniqueCategories = new Set();
    const filteredOptions = [];

    allData.data.forEach((e) => {
      if (area.includes(e.area) && !uniqueCategories.has(e.category)) {
        filteredOptions.push({ value: e.category, label: e.category });
        uniqueCategories.add(e.category);
      }
    });

    setCategoryOptions(filteredOptions);
  }, [getAllHseData, area]);

  const customSelectStylesCreatable = {
    control: (provided) => ({
      ...provided,
      // borderBottom: "none", // Hide the separator
      boxShadow: "none", // Hide the box shadow
      cursor: "pointer", // Show the pointer cursor
      width: "150px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      borderLeft: 0, // Hide the vertical separator beside the dropdown indicator
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index
      position: "absolute",
    }),
  };

  // const handleConfirmSelection = (selectedVariants, removedItems) => {
  //   let map = {};
  //   // Update both states with the selected variants
  //   setSelectedObservations(
  //     [...selectedObservations, ...selectedVariants].filter((e) => {
  //       if (!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //         map[e.sr_no] = true;
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //   );
  //   map = {};
  //   setAllObservations(
  //     [...AllObservations, ...selectedVariants].filter((e) => {
  //       if (!(map[e.sr_no] || removedItems.includes(e.sr_no))) {
  //         map[e.sr_no] = true;
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     })
  //   );
  // };

  const handleConfirmSelection = (selectedVariants, removedItems) => {
    let map = {};

    const getId = (item) => item.sr_no || item.id;

    // Update both states with the selected variants
    setSelectedObservations(
      [...selectedObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );

    map = {};
    setAllObservations(
      [...AllObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );
  };

  const closeVariantModal = () => {
    setOpenVariantModal(false);
  };

  const criticalityOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  const getObservationVariants = async (observation, index) => {
    try {
      if (isEditing && currentEditedRow !== index) {
        toast.warn("Please finish editing the current row.");
        return;
      }
      setOpenVariantModal(true);
      const payload = {
        observation,
        report: "saved",
      };
      const response = await axios.post(
        `${config.PATH}/api/search-hse-data-by-observation`,
        payload
      );

      // Ensure the response data is an array
      if (Array.isArray(response.data)) {
        // Add variant: true to each item in the response data
        const updatedVariants = response.data.map((item) => ({
          ...item,
          variant: true,
        }));
        setObservationVariants(updatedVariants);
      } else {
        console.log("Unexpected response data format:", response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeFacilityInfo = (event) => {
    const { name, value } = event.target;
    setFacilityInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleNewKeyChange = (event) => {
    setNewKey(event.target.value);
  };

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleAddNewField = () => {
    if (newKey && newValue) {
      const keyExists = facilityInfo.hasOwnProperty(newKey);
      const valueExists = facilityInfo[newKey] === newValue;
  
      if (keyExists) {
        alert("This field already exists.");
        return;
      }
  
      setFacilityInfo((prevInfo) => ({
        ...prevInfo,
        [newKey]: newValue,
      }));
      setNewKey("");
      setNewValue("");
    } else {
      alert("Please fill both the fields before adding.");
    }
  };

  // const handleAddNewField = () => {
  //   if (newKey && newValue) {
  //     setFacilityInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [newKey]: newValue,
  //     }));
  //     setNewKey("");
  //     setNewValue("");
  //   } else {
  //     alert("Please fill both the fields before adding.");
  //   }
  // };

  const handleRemoveField = (key) => {
    if (Object.keys(facilityInfo).length === 1) {
      alert("Cannot remove the last field.");
      return;
    }

    const updatedFacilityInfo = { ...facilityInfo };
    delete updatedFacilityInfo[key];
    setFacilityInfo(updatedFacilityInfo);
  };

  const saveFacilityInfo = async () => {
    try {
      const payload = {
        report_id: selectedReportData.report_id,
        facility_info: facilityInfo,
      };
      const res = await axios.post(
        `${config.PATH}/api/save-facility-info`,
        payload
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const paramsOption = () => {
    try {
      const options = [];
      paramList.map((param) => options.push({ label: param, value: param }));
      return options;
    } catch (err) {
      console.log(err);
    }
  };

  const getParamList = () => {
    if (!selectedSector || selectedSector.length === 0) {
      setParamList([]);
      setSelectedParam([]);
      setSelectedArea([]);
      // setAreasToDisplay(null);
      // setCategoriesToDisplay(null);
      // return [];
      return;
    }

    const list = [
      ...new Set(
        allData.data
          .filter((e) => e.sector_type === selectedSector.value) // Filter based on selectedSector
          .map((e) => e.table_type)
          .filter(
            (table_type) =>
              table_type !== null &&
              table_type !== undefined &&
              table_type !== ""
          )
      ),
    ];

    setParamList(list);
    // return list;
  };

  const filteredObservations = AllObservations.filter(
    (observation) =>
      observation.table_type !== null &&
      observation.table_type !== undefined &&
      (observation.observation
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        observation.area.toLowerCase().includes(searchTerm.toLowerCase()) ||
        observation.table_type.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Group filtered observations by table_type
  const groupedByTableType = filteredObservations.reduce(
    (groups, observation) => {
      const { table_type } = observation;
      if (!groups[table_type]) {
        groups[table_type] = [];
      }
      groups[table_type].push(observation);
      return groups;
    },
    {}
  );

  console.log("groupedByTableType", groupedByTableType);

  const allDataForGlobalSearch = allData.data.filter(
    (e) => e.table_type !== null
  );
  const filteredAllData = allDataForGlobalSearch.filter(
    (observation) =>
      observation.observation
        .toLowerCase()
        .includes(globalSearchTerm.toLowerCase()) ||
      observation.area.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
      (observation.table_type &&
        observation.table_type
          .toLowerCase()
          .includes(globalSearchTerm.toLowerCase()))
  );
  // Group filtered observations by area
  const groupedData = filteredAllData.reduce((groups, observation) => {
    const { table_type } = observation;
    if (!groups[table_type]) {
      groups[table_type] = [];
    }
    groups[table_type].push(observation);
    return groups;
  }, {});

  const groupedObservations = selectedObservations.reduce(
    (acc, observation) => {
      if (!acc[observation.table_type]) {
        acc[observation.table_type] = [];
      }
      acc[observation.table_type].push(observation);
      return acc;
    },
    {}
  );

  const renderNewRowInputs = (tableType) => (
    <TableRow key={`new-row-${tableType}`}>
      <TableCell>{groupedObservations[tableType]?.length + 1}</TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.area || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "area")}
          placeholder="Area"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.check_points || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "check_points")}
          placeholder="Check Point"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.observation || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "observation")}
          placeholder="Observation"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.criticality || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "criticality")}
          placeholder="Criticality"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.recommendations || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "recommendations")}
          placeholder="Recommendations"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <textarea
          className="input-field"
          value={newRowInputs[tableType]?.is_reference || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "is_reference")}
          placeholder="IS Reference"
        />
      </TableCell>
      <TableCell className="editable-cell" style={{ height: "100px" }}>
        <input
          // className="input-field"
          value={newRowInputs[tableType]?.score || ""}
          onChange={(e) => handleChangeNewRow(tableType, e, "score")}
          placeholder="Score"
          type="number"
          style={{ height: "31px", marginTop: "-2%" }}
        />
      </TableCell>
      <TableCell>
        <div className="image-container">
          <div className="upload-container">
            <input
              type="file"
              accept="image/*"
              // onChange={(e) => handleImageUploadNewRow(tableType, e.target.files)}
              multiple
              style={{ color: "transparent" }}
              disabled={true}
            />
            {newRowInputs[tableType]?.imageUrls?.length === 0 && (
              <div className="no-file-chosen">No file chosen</div>
            )}
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckIcon
            onClick={() => handleAddRow(tableType)}
            style={{ cursor: "pointer", color: "green" }}
          />
        </div>
      </TableCell>
    </TableRow>
  );

  const handleChangeNewRow = (tableType, e, field) => {
    if (field === "score" && (e.target.value < 0 || e.target.value > 5)) {
      alert("Invalid score. Please enter a value between 0 and 5.");
      return; // Exit the function if the score is invalid
    }

    if (
      (field === "area" || field === "category" || field === "criticality") &&
      e.target.value.length > 100
    ) {
      alert(`${field} cannot exceed 100 characters.`);
      return; // Do not update the state if validation fails
    }

    setNewRowInputs((prev) => ({
      ...prev,
      [tableType]: {
        ...prev[tableType],
        [field]: e.target.value,
      },
    }));
  };

  // const handleAddRow = (tableType) => {
  //   // Create the new row with a fixed key and unique ID
  //   const newRow = {
  //     ...newRowInputs[tableType],
  //     table_type: tableType,
  //     is_selected: 1,
  //     sector_type: selectedSector.value
  //   };

  //   // Replace empty fields with "N/A"
  //   const updatedRow = {
  //     ...newRow,
  //     area: newRow.area,
  //     category: newRow.category?.trim() || "N/A",
  //     check_points: newRow.check_points?.trim() || "N/A",
  //     observation: newRow.observation,
  //     criticality: newRow.criticality?.trim() || "N/A",
  //     recommendations: newRow.recommendations,
  //     is_reference: newRow.is_reference,
  //     score: newRow.score || 0,
  //     imageUrls: newRow.imageUrls?.length > 0 ? newRow.imageUrls : [],
  //   };

  //   // Validate the area and observation fields
  //   if (!updatedRow.area || !updatedRow.observation || !updatedRow.recommendations || !updatedRow.is_reference) {
  //     alert("Area, Observation, Recommendation and IS Reference fields cannot be empty.");
  //     return;
  //   }

  //   // Update the state by pushing the new row into the array
  //   setSelectedObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   setAllObservations((prev) => [
  //     ...prev,  // Spread the existing rows
  //     updatedRow // Add the new row
  //   ]);

  //   // Clear the new row inputs
  //   initializeNewRowInputs(tableType, selectedSector.value);
  // };

  const handleAddRow = (tableType) => {
    // Generate a unique sr_no
    const newSrNo = Date.now() % 1000000;

    // Create the new row with a fixed key, unique sr_no, and other properties
    const newRow = {
      ...newRowInputs[tableType],
      sr_no: newSrNo, // Assign the unique sr_no
      table_type: tableType,
      is_selected: 1,
      sector_type: selectedSector.value,
    };

    // Replace empty fields with "N/A"
    const updatedRow = {
      ...newRow,
      area: newRow.area,
      category: newRow.category?.trim() || "N/A",
      check_points: newRow.check_points?.trim() || "N/A",
      observation: newRow.observation,
      criticality: newRow.criticality?.trim() || "N/A",
      recommendations: newRow.recommendations?.trim() || "N/A",
      is_reference: newRow.is_reference?.trim() || "N/A",
      score: parseInt(newRow.score, 10) || 0,
      imageUrls: newRow.imageUrls?.length > 0 ? newRow.imageUrls : [],
    };

    // Validate the area, observation, recommendations, and is_reference fields
    if (
      !updatedRow.area ||
      !updatedRow.observation ||
      !updatedRow.recommendations ||
      !updatedRow.is_reference
    ) {
      alert(
        "Area, Observation, Recommendation, and IS Reference fields cannot be empty."
      );
      return;
    }

    setEditedObservations((prevObservations) => [
      ...prevObservations,
      updatedRow,
    ]);
    setIsReportEdited(true);

    // Update the state by pushing the new row into the array
    setSelectedObservations((prev) => [
      ...prev, // Spread the existing rows
      updatedRow, // Add the new row
    ]);

    setAllObservations((prev) => [
      ...prev, // Spread the existing rows
      updatedRow, // Add the new row
    ]);

    // Clear the new row inputs
    initializeNewRowInputs(tableType, selectedSector.value);
  };

  if (screenNumber === 1) {
    return (
      <div>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="modal-container">
            <div className="modal-header">
              <Typography variant="h5">
                {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
              </Typography>
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-body">
                Report ID: {`${selectedReportData.report_id}`}
                <Typography variant="body1">
                  <div className="select-container">
                    {/* <Select
                      styles={customSelectStyles}
                      isDisabled={true}
                      value={[
                        {
                          label: selectedReportData.report_id,
                          value: selectedReportData.report_id,
                        },
                      ]}
                    /> */}
                    {/* <div
                      style={{
                        borderStyle: "solid",
                        borderRadius: "5px",
                        borderWidth: "0.05mm",
                        borderColor: "lightgray",
                      }}
                    >
                      <input
                        type="datetime-local"
                        id="datetime-local"
                        value={selectedDateTime}
                        onChange={(e) => handleDateTime(e)}
                        style={{
                          width: "96%",
                          border: "none",
                          fontSize: "15px",
                          padding: "8px",
                          fontFamily: "inherit",
                        }}
                        disabled
                      />
                    </div> */}
                    <CreatableSelect
                      placeholder="Organization"
                      options={orgList.map((e) => ({
                        label: e.org_name,
                        value: e.id,
                      }))}
                      onChange={handleOrganizationSelection}
                      value={selectedOrganization}
                      isSearchable
                      isClearable
                      isDisabled
                    />
                    <CreatableSelect
                      placeholder="Site"
                      options={siteOptions}
                      onChange={handleSiteSelection}
                      value={selectedSite}
                      isSearchable
                      isClearable
                      // isDisabled={!selectedOrganization}
                      isDisabled
                    />
                    {/* 
                    <Select
                      className="select"
                      placeholder="Area"
                      options={areaList.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                      onChange={(e) => handleChangeArea(e)}
                      isMulti={true}
                      defaultValue={selectedArea.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                    />

                    <Select
                      className="select"
                      placeholder="Category"
                      options={[...new Set(categoryList)].map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                      onChange={(e) => handleChangeCategory(e)}
                      isMulti={true}
                      value={selectedCategory.map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                    /> */}
                  </div>
                  <div className="flex-container-start-end-date">
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={new Date(startDate)}
                        onChange={(e) => handleStartEndDate(e, "start-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit Start Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        maxDate={new Date(endDate)}
                        todayButton={"Today"}
                      />
                    </div>
                    <div className="to-date-from-date">
                      <DatePicker
                        selected={new Date(endDate)}
                        onChange={(e) => handleStartEndDate(e, "end-date")}
                        className="class-for-date-pickers"
                        placeholderText="Audit End Date"
                        dateFormat="dd-MM-yyyy"
                        utcOffset={0}
                        minDate={new Date(startDate)}
                        todayButton={"Today"}
                      />
                    </div>
                  </div>
                  <div style={{ gap: "10px" }} className="flex-container">
                    <Select
                      className="select"
                      placeholder="Sector"
                      options={sectors()}
                      onChange={(e) => handleChangeSector(e)}
                      // isMulti={true}
                      value={selectedSector}
                      isClearable
                      defaultValue={selectedSector}
                    />
                    <Select
                      className="select"
                      placeholder="Parameter"
                      options={paramsOption()}
                      onChange={(e) => handleChangeParam(e)}
                      // isMulti={true}
                      value={selectedParam}
                      isClearable
                      isMulti
                    />
                    <Select
                      className="select"
                      placeholder="Area"
                      options={areaList.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                      onChange={(e) => handleChangeArea(e)}
                      isMulti={true}
                      // defaultValue={selectedArea.map((area) => ({
                      //   label: area,
                      //   value: area,
                      // }))}
                      value={selectedArea.map((area) => ({
                        label: area,
                        value: area,
                      }))}
                    />
                    {/* <Select
                      className="select"
                      placeholder="Category"
                      options={[...new Set(categoryList)].map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                      onChange={(e) => handleChangeCategory(e)}
                      isMulti={true}
                      value={selectedCategory.map((cat) => ({
                        label: cat,
                        value: cat,
                      }))}
                    /> */}
                  </div>
                </Typography>
                <div className="observation-and-global-search">
                  <div className="observations-container">
                  <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search Observations"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                          />
                          <div>
                            <button
                              onClick={handleOpenDrawer}
                              className="search-bar-button"
                            >
                              <span className="search-icon">&#128269;</span>{" "}
                              {/* Unicode for search icon */}
                              <span className="search-text">
                                All Observations
                              </span>
                            </button>

                            <ObservationsDrawer
                              isOpen={isDrawerOpen}
                              onClose={handleCloseDrawer}
                              groupedData={groupedData}
                              globalSearchTerm={globalSearchTerm}
                              setGlobalSearchTerm={setGlobalSearchTerm}
                            />
                          </div>
                        </div>
                    {AllObservations.length > 0 && selectedArea.length > 0 ? (
                      <div className="observations-list">
                        {/* {AllObservations.map((observation, index) => (
                        <div key={index} className="observation-item">
                          <input
                            type="checkbox"
                            checked={selectedObservations
                              .map((e) => e.observation)
                              .includes(observation.observation)}
                            onChange={() =>
                              handleObservationSelection(observation, index)
                            }
                          />
                          <span>
                            {observation.observation} (
                            <span style={{ fontWeight: "bold" }}>
                              {observation.area}, {observation.category}
                            </span>
                            )
                          </span>
                        </div>
                      ))} */}
                        {Object.entries(groupedByTableType).map(
                          ([area, observationsInArea]) => (
                            <div key={area} className="area-group">
                              <h4>
                                <u>
                                  <em>{area}</em>
                                </u>
                              </h4>
                              {observationsInArea.map((observation, index) => (
                                <div
                                  key={index}
                                  className="observation-item-checkbox"
                                >
                                  <input
                                    type="checkbox"
                                    checked={observation.is_selected === 1}
                                    onChange={() =>
                                      handleObservationSelection(
                                        observation,
                                        AllObservations.indexOf(observation)
                                      )
                                    }
                                  />
                                  <span>
                                    {observation.observation} (
                                    <span style={{ fontWeight: "bold" }}>
                                      {/* {observation.area}, {observation.category} */}
                                      {observation.area}
                                    </span>
                                    ){" "}
                                    {/* {observation.edited_fields && (
                      <sup>
                        {JSON.parse(observation.edited_fields).map((field, index) => (
                          <span key={index}>
                            {index > 0 && ", "} {field}
                          </span>
                        ))}
                      </sup>
                    )} */}
                                  </span>
                                  {observation.isNote && (
                                    <span className="note-label">(Note)</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div>
                  {/* <div
                    style={{ maxHeight: "45vh" }}
                    className="observations-container"
                  >
                    <input
                      type="text"
                      placeholder="Search all observations"
                      value={globalSearchTerm}
                      onChange={(e) => setGlobalSearchTerm(e.target.value)}
                      className="search-input"
                    />
                    {Object.keys(groupedData).length > 0 ? (
                      <div className="observations-list">
                        {Object.entries(groupedData).map(
                          ([tableType, observationsByType]) => (
                            <div key={tableType} className="table-type-group">
                              <h4>
                                <u>
                                  <em>{tableType}</em>
                                </u>
                              </h4>
                              {observationsByType.map((observation, index) => (
                                <div
                                  key={index}
                                  className="observation-item-checkbox"
                                >
                                  {`${index + 1}.`}&nbsp;
                                  <span>
                                    {observation.observation} (
                                    <span style={{ fontWeight: "bold" }}>
                                      {observation.category}
                                    </span>
                                    )
                                  </span>
                                  {observation.isNote && (
                                    <span className="note-label">(Note)</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="no-observations">
                        No observations available.
                      </div>
                    )}
                  </div> */}
                </div>
                <div className="get-recommendations-container">
                  <button
                    className="button-styles"
                    onClick={handleProceed}
                    disabled={
                      selectedObservations.length === 0 ||
                      !selectedOrganization ||
                      !selectedSite ||
                      !startDate ||
                      !endDate
                    }
                    style={{
                      background:
                        selectedObservations.length === 0 ||
                        !selectedOrganization ||
                        !selectedSite ||
                        !startDate ||
                        !endDate
                          ? "lightgrey"
                          : "#efc71d",
                    }}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </div>
    );
  } else if (screenNumber === 2) {
    return (
      <div>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      BACKGROUND &#8208; PROJECT BRIEF
                    </div>
                    <br />
                    <TextareaAutosize
                      value={backgroundBrief}
                      onChange={(e) => handleChange(e, "background")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </div>
    );
  } else if (screenNumber === 3) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      UNDERSTANDING OF THE REVIEW REPORT &#8208; CONTENTS.
                    </div>
                    <br />
                    <TextareaAutosize
                      value={contents}
                      onChange={(e) => handleChange(e, "contents")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 4) {
    return (
      <>
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Saved Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">INTRODUCTION</div>
                    <br />
                    <TextareaAutosize
                      value={introduction}
                      onChange={(e) => handleChange(e, "intro")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 5) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">EXECUTIVE SUMMARY</div>
                    <br />
                    <TextareaAutosize
                      value={exeSummary}
                      onChange={(e) => handleChange(e, "exe")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 6) {
    return (
      <Modal open={true} onClose={handleClose}>
        <div className="review-modal-container">
          <div className="review-modal-content">
            <div className="review-modal-header">
              <Typography variant="h5">Preview Report</Typography>
              <button className="custom-close-button" onClick={handleClose}>
                &#10005; {/* Unicode for 'X' */}
              </button>
            </div>
            <div className="review-modal-body" style={{ overflowY: "auto" }}>
              <Typography variant="body1">
                <div className="sub-headings">ACADEMIC INFORMATION</div>
                <br />
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>FACILITY INFORMATION</TableCell>
                        <TableCell>COMMENTS & NOTES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {facilityInfo &&
                        Object.entries(facilityInfo).map(
                          ([key, value], index) => (
                            <TableRow key={index}>
                              <TableCell>{key}:</TableCell>
                              <TableCell style={{ display: "flex" }}>
                                <textarea
                                  value={value}
                                  onChange={(e) =>
                                    handleChangeFacilityInfo({
                                      target: {
                                        name: key,
                                        value: e.target.value,
                                      },
                                    })
                                  }
                                  placeholder="Enter your text here"
                                  style={{ width: "100%", minHeight: "80px" }}
                                />
                                <IconButton
                                  onClick={() => handleRemoveField(key)}
                                  style={{ color: "red", marginLeft: "10px" }}
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        )}

                      <TableRow>
                        <TableCell>
                          <TextField
                            label="New Field Name"
                            value={newKey}
                            onChange={handleNewKeyChange}
                            variant="outlined"
                            style={{ width: "100%" }}
                          />
                        </TableCell>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <textarea
                              value={newValue}
                              onChange={(e) =>
                                handleNewValueChange({
                                  target: { value: e.target.value },
                                })
                              }
                              placeholder="Enter your text here"
                              style={{ width: "100%", minHeight: "80px" }}
                            />
                            <button
                              onClick={handleAddNewField}
                              className="button-styles"
                              style={{
                                background: "#efc71d",
                                marginLeft: "10px",
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </div>
            <hr />
            <div className="review-modal-footer">
              <button
                className="button-styles"
                onClick={handleSave}
                style={{ background: "#efc71d" }}
              >
                Save
              </button>
              <button
                className="button-styles"
                onClick={handlePrev}
                style={{ background: "#efc71d" }}
              >
                &#171; Prev
              </button>
              <button
                className="button-styles"
                color="primary"
                onClick={handleNext}
                style={{ background: "#efc71d" }}
              >
                Next &#187;
              </button>
            </div>
          </div>
        </div>
      </Modal>
      // <Modal open={true} onClose={handleClose}>
      //   <div className="review-modal-container">
      //     <div className="review-modal-content">
      //       <div className="review-modal-header">
      //         <Typography variant="h5">Saved Report</Typography>
      //         <button className="custom-close-button" onClick={handleClose}>
      //           &#10005; {/* Unicode for 'X' */}
      //         </button>
      //       </div>
      //       <div className="review-modal-body" style={{ overflowY: "auto" }}>
      //         <Typography variant="body1">
      //           <div className="sub-headings">ACADEMIC INFORMATION</div>
      //           <br />
      //           <TableContainer component={Paper}>
      //             <Table>
      //               <TableBody>
      //                 {facilityInfo &&
      //                   Object.entries(facilityInfo).map(
      //                     ([key, value], index) => (
      //                       <TableRow key={index}>
      //                         <TableCell>{key}:</TableCell>
      //                         <TableCell>
      //                           <ReactQuill
      //                             value={value}
      //                             onChange={(value) =>
      //                               handleChangeFacilityInfo({
      //                                 target: { name: key, value },
      //                               })
      //                             }
      //                             placeholder="Enter your text here"
      //                           />
      //                         </TableCell>
      //                       </TableRow>
      //                     )
      //                   )}

      //                 <TableRow>
      //                   <TableCell>
      //                     <TextField
      //                       label="New Field Name"
      //                       value={newKey}
      //                       onChange={handleNewKeyChange}
      //                       variant="outlined"
      //                       style={{ width: "100%" }}
      //                     />
      //                   </TableCell>
      //                   <TableCell>
      //                     <div
      //                       style={{ display: "flex", alignItems: "center" }}
      //                     >
      //                       <ReactQuill
      //                         value={newValue}
      //                         onChange={(value) =>
      //                           handleNewValueChange({ target: { value } })
      //                         }
      //                         placeholder="Enter your text here"
      //                         style={{ width: "100%" }}
      //                       />
      //                       <button
      //                         onClick={handleAddNewField}
      //                         className="button-styles"
      //                         style={{
      //                           background: "#efc71d",
      //                           marginLeft: "10px",
      //                           height: "56px",
      //                         }}
      //                       >
      //                         Add Field
      //                       </button>
      //                     </div>
      //                   </TableCell>
      //                 </TableRow>
      //               </TableBody>
      //             </Table>
      //           </TableContainer>
      //         </Typography>
      //       </div>
      //       <hr />
      //       <div className="review-modal-footer">
      //         <button
      //           className="button-styles"
      //           onClick={handleSave}
      //           style={{ background: "#efc71d" }}
      //         >
      //           Save
      //         </button>
      //         <button
      //           className="button-styles"
      //           onClick={handlePrev}
      //           style={{ background: "#efc71d" }}
      //         >
      //           &#171; Prev
      //         </button>
      //         <button
      //           className="button-styles"
      //           color="primary"
      //           onClick={handleNext}
      //           style={{ background: "#efc71d" }}
      //         >
      //           Next &#187;
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // </Modal>
    );
    // return (
    //   <Modal open={true} onClose={handleClose}>
    //     <div className="review-modal-container">
    //       <div className="review-modal-content">
    //         <div className="review-modal-header">
    //           <Typography variant="h5">Preview Report</Typography>
    //           <button className="custom-close-button" onClick={handleClose}>
    //             &#10005; {/* Unicode for 'X' */}
    //           </button>
    //         </div>
    //         <div className="review-modal-body" style={{ overflowY: "auto" }}>
    //           <Typography variant="body1">
    //             <div className="sub-headings">FACILITY INFORMATION</div>
    //             <br />
    //             <TableContainer component={Paper}>
    //               <Table>
    //                 <TableBody>
    //                   <TableRow>
    //                     <TableCell style={{ fontSize: "16px" }}>Facility information</TableCell>
    //                     <TableCell style={{ fontSize: "16px" }}>Comments & Notes</TableCell>
    //                   </TableRow>

    //                   <TableRow>
    //                     <TableCell>Name of Facility:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.name}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "name")}
    //                         placeholder="Enter your text here"
    //                         // className="facility-info-text-area"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Address and Location:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.address}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "address")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Geographical Co-ordinates: Seismic Zone</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.coordinates}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "coordinates")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Brief Property Description:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.propertyDescription}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "propertyDescription")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Type of Construction:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.constructionType}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "constructionType")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Average Worker Foot Fall:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.workerFootFall}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "workerFootFall")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>No Objection Certificate:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.noc}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "noc")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>Reference enforcement guidelines:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.guidelines}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "guidelines")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                   <TableRow>
    //                     <TableCell>National Building Code category:</TableCell>
    //                     <TableCell>
    //                       <ReactQuill
    //                         value={facilityInfo.category}
    //                         onChange={(value) => handleChangeFacilityInfo({ target: { value } }, "category")}
    //                         placeholder="Enter your text here"
    //                       />
    //                     </TableCell>
    //                   </TableRow>
    //                 </TableBody>
    //               </Table>
    //             </TableContainer>
    //           </Typography>
    //         </div>
    //         <hr />
    //         <div className="review-modal-footer">
    //           <button className="button-styles" onClick={handleSave} style={{ background: "#efc71d" }}>
    //             Save
    //           </button>
    //           <button className="button-styles" onClick={handlePrev} style={{ background: "#efc71d" }}>
    //             &#171; Prev
    //           </button>
    //           <button className="button-styles" color="primary" onClick={handleNext} style={{ background: "#efc71d" }}>
    //             Next &#187;
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </Modal>
    // );
  } else if (screenNumber === 7) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CRITICAL OBSERVATIONS</div>
                    <br />
                    <div className="critical-observations-div">
                      {criticalObservations.length === 0 ? (
                        <div className="no-observations">
                          <em>No critical observations</em>
                        </div>
                      ) : (
                        criticalObservations.map((observation, index) => (
                          <div key={index} className="observation-item">
                            <textarea
                              onChange={(e) => handleObservationEdit(index, e)}
                              style={{ width: "100%", fontFamily: "inherit" }}
                              value={observation.observation}
                            />
                            &nbsp;
                            <CancelIcon
                              onClick={() => removeItem(index)}
                              className="cancel-icon"
                            >
                              &#10005;
                            </CancelIcon>
                          </div>
                        ))
                      )}
                    </div>
                    <br />
                    <textarea
                      onChange={(e) => handleChange(e, "other details")}
                      value={otherDetails}
                      placeholder="Other Details..."
                      style={{
                        width: "99%",
                        height: "10vh",
                        fontFamily: "inherit",
                      }}
                    />
                  </div>
                </Typography>
              </div>

              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 8) {
    return (
      <>
        <VariantsModal
          data={observationVariants}
          open={openVairantModal}
          handleClose={closeVariantModal}
          handleConfirmSelection={handleConfirmSelection}
        />
        <ImageViewerModal
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div style={{ fontWeight: "600" }} className="sub-headings">
                CRITICAL HSE OBSERVATIONS, PHOTOS & RECOMMENDATIONS
              </div>
              <div
                style={{ overflowY: "scroll" }}
                className="review-modal-body"
              >
                <div className="table-container">
                  {Object.keys(groupedObservations).map((tableType) => (
                    <div key={tableType}>
                      <Typography style={{ fontWeight: "500" }} gutterBottom>
                        {tableType}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        className="table-scroll"
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sr. No.</TableCell>
                              <TableCell>Areas</TableCell>
                              {/* <TableCell>Categories</TableCell> */}
                              <TableCell>Check Point</TableCell>
                              <TableCell>Observation</TableCell>
                              <TableCell>Criticality</TableCell>
                              <TableCell>Recommendation</TableCell>
                              <TableCell>IS Reference</TableCell>
                              <TableCell>Score</TableCell>
                              <TableCell>Photo Evidences</TableCell>
                              <TableCell>Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {groupedObservations[tableType].map(
                              (observation, index) => (
                                <TableRow
                                  key={`${observation.table_type}-${
                                    observation.area
                                  }-${observation.sr_no || observation.id}`}
                                  className={
                                    (isEditing &&
                                      currentEditedRow ===
                                        selectedObservations.findIndex(
                                          (obs) =>
                                            obs.table_type ===
                                              observation.table_type &&
                                            obs.area === observation.area &&
                                            (obs.sr_no === observation.sr_no ||
                                              obs.id === observation.id)
                                        )) ||
                                    !isEditing
                                      ? "even-row"
                                      : "odd-row"
                                  }
                                  style={
                                    observation.variant === true
                                      ? { backgroundColor: "#f2f2f2" }
                                      : {}
                                  }
                                >
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell
                                    className="editable-cell"
                                    style={{ height: "100px" }}
                                  >
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <CreatableSelect
                                        styles={customSelectStylesCreatable}
                                        placeholder="Area"
                                        // options={areaOptions()}
                                        options={areaList.map((area) => ({
                                          label: area,
                                          value: area,
                                        }))}
                                        defaultValue={{
                                          label: observation.area,
                                          value: observation.area,
                                        }}
                                        isSearchable
                                        onChange={(e) =>
                                          handleCellEdit(
                                            e,
                                            "area",
                                            observation.area,
                                            observation
                                          )
                                        }
                                        isDisabled={
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                        }
                                      />
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  {/* Repeat similar structure for other fields */}

                                  <TableCell
                                    className="editable-cell"
                                    style={{ height: "100px" }}
                                  >
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <span
                                        contentEditable={
                                          !observation.variant &&
                                          ((isEditing &&
                                            currentEditedRow ===
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                            !isEditing)
                                        }
                                        onInput={(e) =>
                                          handleCellEdit(
                                            e,
                                            "check_points",
                                            observation.check_points,
                                            observation
                                          )
                                        }
                                      >
                                        {observation.check_points}
                                      </span>
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        onClick={(e) =>
                                          isEditing
                                            ? handleCellEdit(
                                                e,
                                                "check_points",
                                                observation.check_points
                                                  ? observation.check_points
                                                  : "Check Point",
                                                observation
                                              )
                                            : null
                                        }
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell
                                    className="editable-cell"
                                    style={{ height: "100px" }}
                                  >
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <span
                                        contentEditable={
                                          !observation.variant &&
                                          ((isEditing &&
                                            currentEditedRow ===
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                            !isEditing)
                                        }
                                        onInput={(e) =>
                                          handleCellEdit(
                                            e,
                                            "observation",
                                            observation.observation,
                                            observation
                                          )
                                        }
                                      >
                                        {observation.observation}
                                      </span>
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        onClick={(e) =>
                                          isEditing
                                            ? handleCellEdit(
                                                e,
                                                "observation",
                                                observation.observation,
                                                observation
                                              )
                                            : null
                                        }
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell
                                    className="editable-cell"
                                    style={{ height: "100px" }}
                                  >
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <Select
                                        styles={customSelectStylesCreatable}
                                        placeholder="Criticality"
                                        options={criticalityOptions}
                                        defaultValue={{
                                          label: observation.criticality,
                                          value: observation.criticality,
                                        }}
                                        isSearchable
                                        onChange={(e) =>
                                          handleCellEdit(
                                            e,
                                            "criticality",
                                            observation.criticality,
                                            observation
                                          )
                                        }
                                        isDisabled={
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                        }
                                      />
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell className="editable-cell">
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <span
                                        contentEditable={
                                          !observation.variant &&
                                          ((isEditing &&
                                            currentEditedRow ===
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                            !isEditing)
                                        }
                                        onInput={(e) =>
                                          handleCellEdit(
                                            e,
                                            "recommendations",
                                            observation.recommendations,
                                            observation
                                          )
                                        }
                                      >
                                        {observation.recommendations}
                                      </span>
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        onClick={(e) =>
                                          isEditing
                                            ? handleCellEdit(
                                                e,
                                                "recommendations",
                                                observation.recommendations,
                                                observation
                                              )
                                            : null
                                        }
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell
                                    className="editable-cell"
                                    style={{ height: "100px" }}
                                  >
                                    <div
                                      className="cell-content"
                                      style={{
                                        color:
                                          (isEditing &&
                                            currentEditedRow !==
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                          observation.variant === true
                                            ? "grey"
                                            : "black",
                                      }}
                                    >
                                      <span
                                        contentEditable={
                                          !observation.variant &&
                                          ((isEditing &&
                                            currentEditedRow ===
                                              selectedObservations.findIndex(
                                                (obs) =>
                                                  obs.table_type ===
                                                    observation.table_type &&
                                                  obs.area ===
                                                    observation.area &&
                                                  (obs.sr_no ===
                                                    observation.sr_no ||
                                                    obs.id === observation.id)
                                              )) ||
                                            !isEditing)
                                        }
                                        onInput={(e) =>
                                          handleCellEdit(
                                            e,
                                            "is_reference",
                                            observation.is_reference,
                                            observation
                                          )
                                        }
                                      >
                                        {observation.is_reference}
                                      </span>
                                    </div>
                                    {!observation.variant && (
                                      <EditOutlinedIcon
                                        onClick={(e) =>
                                          isEditing
                                            ? handleCellEdit(
                                                e,
                                                "is_reference",
                                                observation.is_reference,
                                                observation
                                              )
                                            : null
                                        }
                                        className="edit-icon"
                                        fontSize="small"
                                      />
                                    )}
                                  </TableCell>

                                  <TableCell>
                                    <input
                                      style={{ width: "35px" }}
                                      value={observation.score}
                                      onChange={(e) =>
                                        handleScoreChange(
                                          e,
                                          observation.table_type,
                                          observation.sr_no
                                        )
                                      }
                                      placeholder="Score"
                                      disabled={
                                        isEditing &&
                                        currentEditedRow !==
                                          selectedObservations.findIndex(
                                            (obs) =>
                                              obs.table_type ===
                                                observation.table_type &&
                                              obs.area === observation.area &&
                                              (obs.sr_no ===
                                                observation.sr_no ||
                                                obs.id === observation.id)
                                          )
                                      }
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <div className="image-container">
                                      {observation.imageUrls?.length > 0 ? (
                                        <div className="image-item">
                                          {observation.imageUrls.map(
                                            (imageUrl, imgIndex) => (
                                              <div
                                                style={{ display: "flex" }}
                                                key={imgIndex}
                                              >
                                                <img
                                                  src={imageUrl}
                                                  alt={`Image ${imgIndex + 1}`}
                                                  className="photo-image-saved"
                                                  onClick={() =>
                                                    setSelectedImage(imageUrl)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                                <CancelIcon
                                                  onClick={() =>
                                                    handleRemoveImage(
                                                      observation.table_type,
                                                      observation.area,
                                                      observation.sr_no ||
                                                        observation.id,
                                                      imgIndex
                                                    )
                                                  }
                                                  className="cancel-icon"
                                                  style={{
                                                    pointerEvents:
                                                      isEditing &&
                                                      currentEditedRow !==
                                                        selectedObservations.findIndex(
                                                          (obs) =>
                                                            obs.table_type ===
                                                              observation.table_type &&
                                                            obs.area ===
                                                              observation.area &&
                                                            (obs.sr_no ===
                                                              observation.sr_no ||
                                                              obs.id ===
                                                                observation.id)
                                                        )
                                                        ? "none"
                                                        : "auto",
                                                  }}
                                                />
                                              </div>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="upload-container">
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                          handleImageUpload(
                                            observation.table_type,
                                            observation.sr_no || observation.id,
                                            observation.area,
                                            e.target.files
                                          )
                                        }
                                        multiple
                                        style={{
                                          color: "transparent",
                                          padding: "5px",
                                          fontSize: "12px",
                                          width: "85px",
                                        }}
                                        disabled={
                                          isEditing &&
                                          currentEditedRow !==
                                            selectedObservations.findIndex(
                                              (obs) =>
                                                obs.table_type ===
                                                  observation.table_type &&
                                                obs.area === observation.area &&
                                                (obs.sr_no ===
                                                  observation.sr_no ||
                                                  obs.id === observation.id)
                                            )
                                        }
                                      />
                                      {observation.imageUrls?.length === 0 && (
                                        <div className="no-file-chosen">
                                          No file chosen
                                        </div>
                                      )}
                                    </div>
                                  </TableCell>

                                  <TableCell className="table-actions">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <InfoIcon
                                        // onClick={() =>
                                        //   getObservationVariants(index)
                                        // }
                                        onClick={() =>
                                          getObservationVariants(
                                            observation.observation,
                                            observation.sr_no || observation.id
                                          )
                                        }
                                      />
                                      <PlaylistAddCircleIcon
                                        onClick={() =>
                                          handleDuplicateRow(
                                            observation.table_type,
                                            observation.area,
                                            observation.sr_no || observation.id
                                          )
                                        }
                                      />
                                      <DeleteForeverIcon
                                        onClick={() =>
                                          handleDeleteRow(
                                            observation.table_type,
                                            observation.area,
                                            observation.sr_no || observation.id
                                          )
                                        }
                                      />
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                            {renderNewRowInputs(tableType)}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <div
                style={{ marginBottom: "10px" }}
                className="review-modal-footer"
              >
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
    // return (
    //   <>
    //     {loading ? <Loader /> : null}
    //     <VariantsModal
    //       data={observationVariants}
    //       open={openVairantModal}
    //       handleClose={closeVariantModal}
    //       handleConfirmSelection={handleConfirmSelection}
    //     />
    //     <ImageViewerModal
    //       imageUrl={selectedImage}
    //       onClose={() => setSelectedImage(null)}
    //     />
    //     <Modal open={true} onClose={handleClose}>
    //       <div className="review-modal-container">
    //         <div className="review-modal-content">
    //           <div className="review-modal-header">
    //             <Typography variant="h5">
    //               {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
    //             </Typography>
    //             <button className="custom-close-button" onClick={handleClose}>
    //               &#10005; {/* Unicode for 'X' */}
    //             </button>
    //           </div>
    //           <div className="sub-headings">
    //             CRITICAL HSE OBSERVATIONS, PHOTOS & RECOMMENDATIONS
    //           </div>
    //           <div className="review-modal-body">
    //             <div className="table-container">
    //               <TableContainer component={Paper} className="table-scroll">
    //                 <Table>
    //                   <TableHead>
    //                     <TableRow>
    //                       <TableCell>Sr. No.</TableCell>
    //                       <TableCell>Areas</TableCell>
    //                       <TableCell>Categories</TableCell>
    //                       <TableCell>Check Point</TableCell>
    //                       <TableCell>Observation</TableCell>
    //                       <TableCell>Criticality</TableCell>
    //                       <TableCell>Recommendation</TableCell>
    //                       <TableCell>IS Reference</TableCell>
    //                       <TableCell>Score</TableCell>
    //                       <TableCell>Photo Evidences</TableCell>
    //                       <TableCell>Actions</TableCell>
    //                     </TableRow>
    //                   </TableHead>
    //                   <TableBody>
    //                     {selectedObservations.map((observation, index) => (
    //                       <TableRow
    //                         key={index}
    //                         className={
    //                           (isEditing && currentEditedRow === index) ||
    //                           !isEditing
    //                             ? "even-row"
    //                             : "odd-row"
    //                         }
    //                       >
    //                         <TableCell>{index + 1}</TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Area"
    // options={areaList.map((area) => ({
    //   label: area,
    //   value: area,
    // }))}
    //                               defaultValue={{
    //                                 label: observation.area,
    //                                 value: observation.area,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "area",
    //                                   observation.area,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Category..."
    //                               options={categoryOptions}
    //                               defaultValue={{
    //                                 label: observation.category,
    //                                 value: observation.category,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "category",
    //                                   observation.category,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         {/* <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "area",
    //                                   observation.area,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.area}
    //                             </span>
    //                           </div>
    //                           <EditIcon
    //                             onClick={(e) =>
    //                               isEditing
    //                                 ? handleCellEdit(
    //                                     e,
    //                                     index,
    //                                     "area",
    //                                     observation.area,
    //                                     observation
    //                                   )
    //                                 : null
    //                             }
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "category",
    //                                   observation.category,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.category}
    //                             </span>
    //                           </div>
    //                           <EditIcon
    //                             onClick={(e) =>
    //                               isEditing
    //                                 ? handleCellEdit(
    //                                     e,
    //                                     index,
    //                                     "category",
    //                                     observation.category,
    //                                     observation
    //                                   )
    //                                 : null
    //                             }
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />
    //                         </TableCell> */}
    //                         {/* <TableCell>{observation.check_points}</TableCell> */}
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 isEditing && currentEditedRow !== index
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "check_points",
    //                                   observation.check_points,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.check_points}
    //                             </span>
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "check_points",
    //                                       observation.check_points
    //                                         ? observation.check_points
    //                                         : "Check Point",
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 isEditing && currentEditedRow !== index
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "observation",
    //                                   observation.observation,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.observation}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "observation",
    //                                       observation.observation,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <Select
    //                             styles={customSelectStylesCreatable}
    //                             placeholder="Criticality"
    //                             options={criticalityOptions}
    //                             defaultValue={{
    //                               label: observation.criticality,
    //                               value: observation.criticality,
    //                             }}
    //                             isSearchable
    //                             onChange={(e) =>
    //                               handleCellEdit(
    //                                 e,
    //                                 index,
    //                                 "criticality",
    //                                 observation.criticality,
    //                                 observation
    //                               )
    //                             }
    //                             isDisabled={
    //                               isEditing && currentEditedRow !== index
    //                             }
    //                           />
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell className="editable-cell">
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 isEditing && currentEditedRow !== index
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "recommendations",
    //                                   observation.recommendations,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.recommendations}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "recommendations",
    //                                       observation.recommendations,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div
    //                             className="cell-content"
    //                             style={{
    //                               color:
    //                                 isEditing && currentEditedRow !== index
    //                                   ? "grey"
    //                                   : "black",
    //                             }}
    //                           >
    //                             <span
    //                               contentEditable={
    //                                 (isEditing && currentEditedRow === index) ||
    //                                 !isEditing
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "is_reference",
    //                                   observation.is_reference,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.is_reference}
    //                             </span>
    //                           </div>
    //                           <EditOutlinedIcon
    //                             onClick={(e) =>
    //                               isEditing
    //                                 ? handleCellEdit(
    //                                     e,
    //                                     index,
    //                                     "is_reference",
    //                                     observation.is_reference,
    //                                     observation
    //                                   )
    //                                 : null
    //                             }
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />
    //                         </TableCell>
    //                         <TableCell>
    //                           <input
    //                             style={{ width: "35px" }}
    //                             value={observation.score}
    //                             onChange={(e) => handleScoreChange(e, index)} // Implement this function
    //                             placeholder="Score"
    //                             disabled={
    //                               isEditing && currentEditedRow !== index
    //                             }
    //                           />
    //                         </TableCell>
    //                         <TableCell>
    //                           <div className="image-container">
    //                             {observation.imageUrls?.length > 0 ? (
    //                               <div className="image-item">
    //                                 {observation.imageUrls.map(
    //                                   (imageUrl, imgIndex) => (
    //                                     <div
    //                                       style={{ display: "flex" }}
    //                                       key={imgIndex}
    //                                     >
    //                                       <img
    //                                         src={imageUrl}
    //                                         alt={`Image ${imgIndex + 1}`}
    //                                         className="photo-image-saved"
    //                                         onClick={() =>
    //                                           setSelectedImage(imageUrl)
    //                                         } // Set selected image on click
    //                                         style={{ cursor: "pointer" }}
    //                                       />
    //                                       <CancelIcon
    //                                         onClick={() =>
    //                                           handleRemoveImage(index, imgIndex)
    //                                         }
    //                                         className="cancel-icon"
    //                                         style={{
    //                                           pointerEvents:
    //                                             isEditing &&
    //                                             currentEditedRow !== index
    //                                               ? "none"
    //                                               : "auto",
    //                                         }}
    //                                       />
    //                                     </div>
    //                                   )
    //                                 )}
    //                               </div>
    //                             ) : (
    //                               ""
    //                             )}
    //                           </div>
    //                           <div className="upload-container">
    //                             <input
    //                               type="file"
    //                               accept="image/*"
    //                               onChange={(e) =>
    //                                 handleImageUpload(index, e.target.files)
    //                               }
    //                               multiple
    //                               style={{
    //                                 color: "transparent",
    //                                 padding: "5px", // You can adjust the padding to reduce the size
    //                                 fontSize: "12px", // You can adjust the font size to make it smaller
    //                                 // Add any other styling as needed
    //                                 width: "85px",
    //                               }}
    //                               disabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             />
    //                             {observation.imageUrls?.length === 0 && (
    //                               <div className="no-file-chosen">
    //                                 No file chosen
    //                               </div>
    //                             )}
    //                           </div>
    //                         </TableCell>
    //                         <TableCell>
    //                           <div style={{ display: "flex" }}>
    //                             <InfoIcon
    //                               onClick={() =>
    //                                 getObservationVariants(
    //                                   observation.observation,
    //                                   index
    //                                 )
    //                               }
    //                               style={{ cursor: "pointer" }}
    //                             />

    //                             <PlaylistAddCircleIcon
    //                               onClick={() => handleDuplicateRow(index)}
    //                               style={{ cursor: "pointer" }}
    //                             />
    //                             <DeleteForeverIcon
    //                               onClick={() => handleDeleteRow(index)}
    //                               style={{ cursor: "pointer" }}
    //                             />
    //                           </div>
    //                         </TableCell>
    //                       </TableRow>
    //                     ))}
    //                   </TableBody>
    //                 </Table>
    //               </TableContainer>
    //             </div>
    //           </div>
    //           <hr />
    //           <div className="review-modal-footer">
    //             <button
    //               className="button-styles"
    //               onClick={handleSave}
    //               style={{ background: "#efc71d" }}
    //             >
    //               Save
    //             </button>
    //             <button
    //               className="button-styles"
    //               onClick={handlePrev}
    //               style={{ background: "#efc71d" }}
    //             >
    //               &#171; Prev
    //             </button>
    //             <button
    //               className="button-styles"
    //               color="primary"
    //               onClick={() => handleNext()}
    //               style={{ background: "#efc71d" }}
    //             >
    //               Next &#187;
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal>
    //   </>
    // );
  } else if (screenNumber === 9) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">GLOBAL BEST PRACTICES</div>
                    <br />
                    <TextareaAutosize
                      value={bestPractice}
                      onChange={(e) => handleChange(e, "best_practice")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 10) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Charts</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <div
                  id="chart-container-for-report"
                  className="chart-container-for-report"
                  ref={chartContainerRef}
                >
                  <div className="total-serverity-div-for-report">
                    <div className="severity-item-for-report">
                      Total Observations
                      <br />
                      <span>{data.length}</span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#FF0000" }}
                      className="severity-item-for-report"
                    >
                      High Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "High").length}
                      </span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#006400" }}
                      className="severity-item-for-report"
                    >
                      Medium Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "Medium").length}
                      </span>
                      <hr />
                    </div>
                    <div
                      style={{ color: "#005cdb" }}
                      className="severity-item-for-report"
                    >
                      Low Severity Observations
                      <br />
                      <span>
                        {data.filter((e) => e.criticality === "Low").length}
                      </span>
                      <hr />
                    </div>
                  </div>
                  <div className="area-chart-for-report">
                    Area Chart
                    <Chart
                      options={barOptions}
                      series={[
                        {
                          name: "",
                          data: counts,
                        },
                      ]}
                      type="bar"
                      height={300}
                    />
                  </div>
                  <div className="severity-chart-for-report">
                    Severity Chart
                    <Chart
                      options={severityChartOptions}
                      series={seriesData}
                      type="bar"
                      height={300}
                    />
                  </div>
                  <div className="pie-chart-for-report">
                    Audit Score
                    <Chart
                      options={pieOptions}
                      series={[scorePercent, 100 - scorePercent]}
                      type="pie"
                      style={{ width: "100%" }}
                      height={250}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  {waitForCharts ? "Wait..." : "Save"}
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171; {waitForCharts ? "Wait..." : "Prev"}
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={waitForCharts}
                  style={{
                    background: waitForCharts ? "lightgrey" : "#efc71d",
                  }}
                >
                  {waitForCharts ? "Wait..." : "Next"} &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 11) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">
                  {module === "cmv" ? " HSE CMV Report" : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">THE WAY FORWARD</div>
                    <br />
                    <TextareaAutosize
                      value={theWayForward}
                      onChange={(e) => handleChange(e, "the_way_forward")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* {showConfirmationModal && (
          <ConfirmationModal
            setShowConfirmationModal={setShowConfirmationModal}
            handleCloseWithoutSaving={handleCloseWithoutSaving}
          />
        )} */}
      </>
    );
  } else if (screenNumber === 12) {
    return (
      <>
        {loading ? <Loader /> : null}
        <Modal open={true} onClose={handleClose}>
          <div className="review-modal-container">
            <div
              className={exp ? "export-modal-content" : "review-modal-content"}
            >
              <div className="review-modal-header">
                <Typography variant="h5">
                  {exp
                    ? "Export Report"
                    : module === "cmv"
                    ? "HSE CMV Report"
                    : "Saved Report"}
                </Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              {/* <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CONCLUSION</div>
                    <br />
                    <TextareaAutosize
                      value={conclusion}
                      onChange={(e) => handleChange(e, "conclusion")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div> */}
              {!exp && (
                <div
                  className="review-modal-body"
                  style={{ overflowY: "auto" }}
                >
                  <Typography variant="body1">
                    <div>
                      <div className="sub-headings">CONCLUSION</div>
                      <br />
                      <TextareaAutosize
                        value={conclusion}
                        onChange={(e) => handleChange(e, "conclusion")}
                        placeholder="Enter your text here"
                        className="text-area"
                        style={{ background: "whitesmoke" }}
                      />
                    </div>
                  </Typography>
                </div>
              )}
              <hr />
              <div className="review-modal-footer">
                {/* <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                {module !== "cmv" ? (
                  <button
                    className="button-styles"
                    onClick={handleComplete}
                    disabled={isComplete}
                    style={{ background: isComplete ? "lightgrey" : "#efc71d" }}
                  >
                    Complete
                  </button>
                ) : null} */}
                {!exp && (
                  <>
                    <button
                      className="button-styles"
                      onClick={handleSave}
                      style={{ background: "#efc71d" }}
                    >
                      Save
                    </button>
                    <button
                      className="button-styles"
                      onClick={handlePrev}
                      style={{ background: "#efc71d" }}
                    >
                      &#171; Prev
                    </button>
                    {module !== "cmv" && (
                      <button
                        className="button-styles"
                        onClick={handleComplete}
                        disabled={isComplete}
                        style={{
                          background: isComplete ? "lightgrey" : "#efc71d",
                        }}
                      >
                        Complete
                      </button>
                    )}
                  </>
                )}
                <ExportSavedReportPDF
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  imageUrlsByRow={imageUrlsByRow}
                  reportType="HSE"
                  isSaved={exp ? true : isSaved}
                  chartImage={chartImage}
                  otherDetails={otherDetails}
                  ReportUID={selectedReportData.report_id}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  name={name}
                  facilityInfo={facilityInfo}
                  introduction={introduction}
                />
                <ExportExcel
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  imageUrlsByRow={imageUrlsByRow}
                  isSaved={exp ? true : isSaved}
                  reportType="HSE"
                  otherDetails={otherDetails}
                  ReportUID={selectedReportData.report_id}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  facilityInfo={facilityInfo}
                />
                <ExportWordDoc
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={exp ? true : isSaved}
                  otherDetails={otherDetails}
                  reportType="HSE"
                  chartImage={chartImage}
                  ReportUID={selectedReportData.report_id}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  name={name}
                  facilityInfo={facilityInfo}
                  introduction={introduction}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default HseSavedReportModal;