import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField
} from "@mui/material";
import "./PreviewReportModal.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ExportSavedReportPDF from "../ExportSavedReportPDF/ExportSavedReportPDF";
import ExportExcel from "../ExportExcel/ExportExcel";
import ExportWordDoc from "../ExportWordDoc/ExportWordDoc";
import { config } from "../../config";
import axios from "../../APIs/axios";
import { toast } from "react-toastify";
import { getAccountDetails } from "../Services/localStorage";
import Electrical_Cover from "../../Electrical_Cover.jpg";
import EditIcon from "@material-ui/icons/Edit";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";
import CreatableSelect from "react-select/creatable";
import VariantsModal from "../VariantsModal/VariantsModal";
import Select from "react-select";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";


const PreviewReportModal = ({
  open,
  setOpenModal,
  setOpenCreateReportModal,
  reportHeader,
  selectedObservations,
  recommendations,
  selectedArea,
  selectedCategory,
  setSelectedObservations,
  selectedOrganization,
  selectedSite,
  setRecommendations,
  setSelectedArea,
  setSelectedCategory,
  setSelectedOrganization,
  setSelectedSite,
  ReportUID,
  selectedDateTime,
  observations,
  setCategoriesToDisplay,
  setAreasToDisplay,
  setObservations,
  getAllData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  generateUniqueId,
  // getCategoriesByAreas,
  getCurrentDateTime,
  setLoading,
  areaOptions,
  allData,
  getAllReports,
}) => {
  const [backgroundBrief, setBackgroundBrief] = useState(
    reportHeader.background_brief
      .replace("Alpha Maier Pvt.Ltd", `${selectedOrganization.label}`)
      .replace("Manesar plant", `${selectedSite.label}`)
      .replace(
        "30th & 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
  );
  const [contents, setContents] = useState(reportHeader.contents);
  const [bestPractice, setbestPractice] = useState(reportHeader.best_practice);
  const [theWayForward, setTheWayForward] = useState(
    reportHeader.the_way_forward
  );
  const [exeSummary, setExeSummary] = useState(
    reportHeader.exe_summary
      .replace(
        "Alpha Maier private Ltd",
        `${selectedOrganization.label}(${selectedSite.label})`
      )
      .replace(
        "30th &amp; 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
      .replace("<areas>", selectedArea.join(","))
  );
  const [criticalObservations, setCriticalObservations] = useState(
    selectedObservations
      .filter((e) => e.criticality === "High")
      .map((observation) => ({ ...observation }))
  );

  const [otherDetails, setOtherDetails] = useState("");
  const [conclusion, setConclusion] = useState(reportHeader.conclusion);
  const [screenNumber, setScreenNumber] = useState(1);
  // const [imageUrlsByRow, setImageUrlsByRow] = useState({});
  const { userId, name } = getAccountDetails();
  const [editedObservations, setEditedObservations] = useState([]);
  const [isReportEdited, setIsReportEdited] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [currentEditedRow, setCurrentEditedRow] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [disableSaveNext, setDisableSaveNext] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [area, setArea] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [editedFields, setEditedFields] = useState([]);
  const [observationVariants, setObservationVariants] = useState([]);
  const [openVairantModal, setOpenVariantModal] = useState(false);
  const [confirmationShown, setConfirmationShown] = useState(false);
  const [isRowSaved, setIsRowSaved] = useState(true)
  const [facilityInfo, setFacilityInfo] = useState({
    "Name of Facility": "", 
    "Address & Location": "",
    "Geographical Co-ordinates Seismic Zone": "",
    "Brief Property Description:": "",
    "Type of Construction": "",
    "Number of Floors":"",
    "Average Worker Foot Fall:": "",
    "No Objection Certificate": "",
  });
  const [scores, setScores] = useState([
    {
      "Electrical Safety": "Design & Installation",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Preventive maintenance",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Competency/Training",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Lock out-Tag out",
      "Max Score": 2,
      "Score Obtained": 0,
    },
    {
      "Electrical Safety": "Drawings (As built) / Documents",
      "Max Score": 2,
      "Score Obtained": 0,
    },
  ]);
  const [newRow, setNewRow] = useState({
    area: "",
    category: "",
    check_points: "",
    observation: "",
    criticality: "",
    recommendations: "",
    is_reference: "",
    imageUrls: [],
  });
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const cumulativeScore =
    scores.reduce(
      (acc, row) => acc + parseFloat(row["Score Obtained"] || 0),
      0
    ) > 0
      ? scores
          .reduce((acc, row) => acc + parseFloat(row["Score Obtained"] || 0), 0)
          .toFixed(2)
      : 0;

  useEffect(() => {
    updateBackgroundBrief();
    updateExecSummary();
  }, [selectedOrganization, selectedSite, startDate, endDate, selectedArea]);

  const updateBackgroundBrief = () => {
    const updatedData = reportHeader.background_brief
      .replace("Alpha Maier Pvt.Ltd", `${selectedOrganization.label}`)
      .replace("Manesar plant", `${selectedSite.label}`)
      .replace(
        "30th & 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} and ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      );
    setBackgroundBrief(updatedData);
  };

  const updateExecSummary = () => {
    const updatedData = reportHeader.exe_summary
      .replace(
        "Alpha Maier private Ltd",
        `${selectedOrganization.label}(${selectedSite.label})`
      )
      .replace(
        "30th &amp; 31st March 2022",
        `${startDate.getDate()}-${
          startDate.getMonth() + 1
        }-${startDate.getFullYear()} till ${endDate.getDate()}-${
          endDate.getMonth() + 1
        }-${endDate.getFullYear()}`
      )
      .replace("<areas>", selectedArea.join(","));
    setExeSummary(updatedData);
  };

  const handleCellEdit = (e, index, field, originalContent, observationObj) => {
    // Function to handle the confirmation dialog
    const handleConfirmation = () => {
      if (!confirmationShown) {
        const confirmEdit = window.confirm(
          "Editing the observation field will make this a new observation set and the variant list will be updated accordingly. Do you want to continue?"
        );
        if (!confirmEdit) {
          // Revert the text content if the user cancels
          e.target.textContent = originalContent;
          return false;
        } else {
          setConfirmationShown(true); // User confirmed, set the flag
          return true;
        }
      }
      return true;
    };

    // Show the confirmation dialog if the field is "observation"
    if (field === "observation") {
      if (!handleConfirmation()) {
        setIsEditing(false);
        return; // Exit if the user cancels the confirmation
      }
    }

    setIsEditing(true);

    // Function to update edited fields array
    const updateEditedFields = (newField) => {
      if (!editedFields.includes(newField)) {
        setEditedFields((prevFields) => [...prevFields, newField]);
      }
    };

    if (field === "area") {
      let area = [];
      area.push(e.value);
      setArea(area);
      updateEditedFields("A");
    }

    let currentContent;
    if (field === "area" || field === "category" || field === "criticality") {
      currentContent = e.value;
    } else {
      currentContent = e.target.textContent;
    }

    // Check if the current content exceeds the character limit
    if (
      (field === "area" || field === "category") &&
      currentContent.length > 50
    ) {
      toast.warning("Only 50 characters are allowed in this field.");
      setIsEditing(false);
      return; // Do nothing and exit the function
    }

    if (currentContent.length > 7000) {
      toast.warning("Maximum character limit reached.");
      e.target.textContent = currentContent.substring(0, 7000);
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(e.target.childNodes[0], 7000);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      e.preventDefault();
      return;
    }

    setIsReportEdited(true);
    if (currentEditedRow !== -1 && currentEditedRow !== index) {
      toast.warning(
        "Please save changes in the currently edited row before editing another row."
      );
      return;
    }

    setCurrentEditedRow(index);

    let value;
    if (field === "area" || field === "category" || field === "criticality") {
      value = e.value;
    } else {
      value = e.target.textContent;
    }

    const updatedObservations = [...editedObservations];
    let i = updatedObservations.length ? updatedObservations.length - 1 : 0;
    updatedObservations[i] = {
      ...observationObj,
      ...updatedObservations[i],
      [field]: value,
      selectedRefIndex: index,
    };

    setEditedObservations(updatedObservations);

    // Check if the content has been reverted to the original
    const isReverted =
      field === "observation"
        ? e.target.textContent === originalContent
        : field === "recommendations"
        ? e.target.textContent === originalContent
        : field === "area"
        ? e.value === originalContent
        : field === "criticality"
        ? e.value === originalContent
        : field === "is_reference"
        ? e.target.textContent === originalContent
        : field === "category"
        ? e.value === originalContent
        : false;

    // If content is reverted, disable editing and enable other rows
    if (isReverted) {
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setEditedObservations([]);
      setConfirmationShown(false); // Reset confirmation shown when content is reverted
    }

    // Update edited fields array based on the field edited
    switch (field) {
      case "category":
        updateEditedFields("CA");
        break;
      case "check_points":
        updateEditedFields("CH");
        break;
      case "observation":
        updateEditedFields("O");
        break;
      case "criticality":
        updateEditedFields("CR");
        break;
      case "recommendations":
        updateEditedFields("R");
        break;
      case "is_reference":
        updateEditedFields("I");
        break;
      // Add cases for other fields as needed
      default:
        break;
    }
  };

  const handleChange = (e, name) => {
    setIsSaved(false);
    if (name === "background") {
      setBackgroundBrief(e.target.value);
    } else if (name === "contents") {
      setContents(e.target.value);
    } else if (name === "exe") {
      setExeSummary(e.target.value);
    } else if (name === "conclusion") {
      setConclusion(e.target.value);
    } else if (name === "other details") {
      setOtherDetails(e.target.value);
    } else if (name === "best_practice") {
      setbestPractice(e.target.value);
    } else if (name === "the_way_forward") {
      setTheWayForward(e.target.value);
    }
  };

  const handleNext = () => {
    if (screenNumber === 7 && isRowSaved === false) {
      toast.warning("Please save the new row by clicking on tick action button.");
      setLoading(false);
      return;
    }

    if (isEditing) {
      toast.warning("Please save changes before proceeding.");
      return;
    }
    setScreenNumber(screenNumber + 1);
  };

  const handlePrev = () => {
    if (isEditing) {
      toast.warning("Please save changes before going back.");
      return;
    }
    if (screenNumber === 7 && isRowSaved === false) {
      toast.warning("Please save the new row by clicking on tick action button.");
      setLoading(false);
      return;
    }
    setScreenNumber(screenNumber - 1);
  };

  const handleEdit = () => {
    setOpenModal(false);
    setOpenCreateReportModal(true);
  };

  const removeItem = (index) => {
    const updatedObservations = [...criticalObservations];
    updatedObservations.splice(index, 1);
    setCriticalObservations(updatedObservations);
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 800;

        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.7);
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const handleImageUpload = async (index, files) => {
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...observations];
    const editedObsCopy = [...editedObservations];

    if (!selectedObsCopy[index].imageUrls) {
      selectedObsCopy[index].imageUrls = [];
    }

    try {
      setLoading(true);

      for (const file of Array.from(files)) {
        if (!file.type.startsWith("image/")) {
          throw new Error("Only image files are allowed.");
        }

        const compressedImage = await compressImage(file);

        const formData = new FormData();
        formData.append("image", compressedImage);

        const response = await axios.post(
          `${config.PATH}/api/upload/image `,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const imageUrl = response.data.imageUrl;
        selectedObsCopy[index].imageUrls.push(imageUrl);

        // Update editedObservations if it has values
        if (editedObsCopy.length > 0) {
          const editedIndex = editedObsCopy.findIndex(
            (observation) =>
              observation.refIndex === selectedObsCopy[index].refIndex
          );
          if (editedIndex !== -1) {
            editedObsCopy[editedIndex].imageUrls =
              selectedObsCopy[index].imageUrls;
          }
        }
      }

      selectedObsCopy.forEach((e) => {
        obsCopy[e.refIndex] = {
          ...obsCopy[e.refIndex],
          imageUrls: e.imageUrls,
        };
      });

      setSelectedObservations(selectedObsCopy);
      setObservations(obsCopy);

      // Update editedObservations only if it has values
      if (editedObsCopy.length > 0) {
        setEditedObservations(editedObsCopy);
      }

      setLoading(false);
    } catch (error) {
      console.log("Error uploading image:", error);
      toast.error(error.message);
    }
  };

  const handleRemoveImage = (index, imageIndex) => {
    const selectedObsCopy = [...selectedObservations];
    const obsCopy = [...observations];

    if (selectedObsCopy[index].imageUrls) {
      selectedObsCopy[index].imageUrls.splice(imageIndex, 1);

      // If there are no more images in the array, remove the property
      if (selectedObsCopy[index].imageUrls.length === 0) {
        delete selectedObsCopy[index].imageUrls;
      }

      // Update the corresponding observation in obsCopy
      obsCopy[selectedObsCopy[index].refIndex] = {
        ...obsCopy[selectedObsCopy[index].refIndex],
        imageUrls: selectedObsCopy[index].imageUrls,
      };

      // Update state
      setSelectedObservations(selectedObsCopy);
      setObservations(obsCopy);
    }
  };

  const keyNotToCheck = [
    "remarks",
    "score",
    "refIndex",
    "selectedRefIndex",
    "equipment",
    "is_selected",
    "imageUrls",
    "edited_fields",
    "status",
    "sector_type",
    "table_type"
  ];

  const handleSave = async (complete) => {
    try {
      setLoading(true);

      if (screenNumber === 7 && isRowSaved === false) {
        toast.warning("Please save the new row by clicking on tick action button");
        setLoading(false);
        return;
      }

      if (isAnyScoreEmpty()) {
        toast.warning("Please fill the score table before saving the report.");
        setLoading(false);
        return;
      }

      let updatedSelectedObservations = [...selectedObservations];
      let updatedAllObservations = [...observations];

      if (editedObservations[0]) {
        console.log(
          Object.entries(editedObservations[0]).filter(
            (e) => !(keyNotToCheck.includes(e[0]) || e[1])
          )
        );
        if (
          Object.entries(editedObservations[0]).filter(
            (e) => !(keyNotToCheck.includes(e[0]) || e[1])
          ).length > 0
        ) {
          toast.error("Table fields can't be empty.");
          setLoading(false);
          return;
        }

        editedObservations.forEach((obj) => {
          updatedSelectedObservations[obj.selectedRefIndex] = obj;
          updatedAllObservations[obj.refIndex] = { ...obj, is_selected: 1 };
        });

        if (isReportEdited) {
          const payload = editedObservations
            .map(({ sr_no, score, edited_fields, ...rest }) => ({
              ...rest,
              edited_fields: editedFields,
            }))
            .filter((item) => Object.keys(item).length > 0);

          if (payload.length > 0) {
            await axios.post(`${config.PATH}/api/insert-new-row`, payload);
          }
        } else {
          //pass
        }

        setSelectedObservations(updatedSelectedObservations);
        setObservations(updatedAllObservations);
      }

      const reportData = {
        report_id: ReportUID,
        user_id: userId,
        date_time: selectedDateTime,
        organization: selectedOrganization
          ? selectedOrganization.label
          : selectedOrganization,
        site: selectedSite ? selectedSite.label : selectedSite,
        org_id: selectedOrganization
          ? selectedOrganization.value
          : selectedOrganization,
        area: selectedArea,
        category: selectedCategory,
        background_brief: backgroundBrief,
        contents: contents,
        exe_summary: exeSummary,
        conclusion: conclusion,
        best_practice: bestPractice,
        the_way_forward: theWayForward,
        scores: scores,
        cumulative: cumulativeScore,
        is_edited: isReportEdited,
        other_details: otherDetails,
        is_saved: 1,
        is_complete: complete === true ? true : false,
        start_date: startDate,
        end_date: endDate,
      };

      if (module === "cmv") {
        reportData.type = "cmv";
      } else {
        reportData.type = "primary";
      }

      const cmvEndPOint = `${config.PATH}/api/save-update-cmv-report`;
      const reportEndPoint = `${config.PATH}/api/save-update-report`;

      if (complete === true) {
        await axios.post(reportEndPoint, reportData);
        await axios.post(cmvEndPOint, reportData);
      }

      await axios.post(reportEndPoint, reportData);

      const observationsData = {
        report_id: ReportUID,
        all_observations: updatedAllObservations.map((e) => {
          if (e.id) {
            e.sr_no = e.id;
          }
          return e;
        }),
        organization: selectedOrganization.label,
        site: selectedSite.label,
      };

      const observationEndpoint =
        complete === true
          ? `${config.PATH}/api/save-update-cmv-observations`
          : `${config.PATH}/api/save-update-observations`;

      await axios.post(observationEndpoint, observationsData);

      setIsSaved(true);
      setEditedObservations([]);
      setCurrentEditedRow(-1);
      setIsEditing(false);
      setDisableSaveNext(false);
      getAllData();
      updateOrgReportStatus();
      updateSiteReportStatus();
      setEditedFields([]);
      toast.success(
        `${complete === true ? "Report Completed and Saved" : "Report Saved"}`
      );
      saveCriticalObservations(complete);
      saveFacilityInfo();
      setLoading(false);
      setConfirmationShown(false);
      getAllReports();
    } catch (error) {
      console.log("Error saving report:", error);
    }
  };

  const closeReport = () => {
    if (isAnyScoreEmpty()) {
      toast.warning("Please fill the score table before closing the report.");
      return;
    }
    setOpenModal(false);
    setSelectedObservations([]);
    setRecommendations([]);
    setSelectedArea([]);
    setSelectedCategory([]);
    setSelectedOrganization([]);
    setSelectedSite([]);
    setAreasToDisplay([]);
    setCategoriesToDisplay([]);
    setStartDate(null);
    setEndDate(null);
    generateUniqueId();
    // getCategoriesByAreas();
    getCurrentDateTime();
  };

  const handleClose = () => {
    if (isEditing && screenNumber === 7) {
      toast.warning("Please save changes before closing the report.");
      return;
    } else if (!isSaved) {
      toast.warning("Please save the report before closing.");
    } else {
      closeReport();
    }
  };

  const handleDuplicateRow = (index) => {
    if (isEditing) {
      toast.warn(
        "Please finish editing the current row before adding a new row."
      );
      return;
    }

    const selectedOriginalRow = selectedObservations[index];

    // Check if imageUrls is defined, and create a deep copy if it is, or an empty array otherwise
    const duplicatedRowForSelected = {
      ...selectedOriginalRow,
      refIndex: selectedOriginalRow.refIndex + 1,
      imageUrls: selectedOriginalRow.imageUrls
        ? [...selectedOriginalRow.imageUrls]
        : [],
    };

    const updatedAllObservations = [...observations];
    const updatedSelectedObservations = [...selectedObservations];

    updatedAllObservations.splice(selectedOriginalRow.refIndex + 1, 0, {
      ...duplicatedRowForSelected,
      is_selected: 1,
    });
    updatedSelectedObservations.splice(index + 1, 0, duplicatedRowForSelected);

    setObservations(updatedAllObservations);
    setSelectedObservations(updatedSelectedObservations);

    toast.success("New row added");
  };

  const handleDeleteRow = (index) => {
    if (isEditing && currentEditedRow !== index) {
      toast.warn(
        "Please finish editing the current row before deleting the row."
      );
      return;
    }
    const updatedSelectedObservations = [...selectedObservations];
    const updatedAllObservations = [...observations];

    // Check if there is only one row left
    if (updatedSelectedObservations.length === 1) {
      toast.warn("Cannot delete the last row.");
      return;
    }

    const refIndex = selectedObservations[index].refIndex;

    updatedSelectedObservations.splice(index, 1);
    updatedAllObservations.splice(refIndex, 1);
    updatedSelectedObservations.forEach(
      (e) => (e.refIndex = e.refIndex > refIndex ? e.refIndex - 1 : e.refIndex)
    );
    setSelectedObservations(updatedSelectedObservations);
    setObservations(updatedAllObservations);
    setIsEditing(false);
    setEditedObservations([]);
    setCurrentEditedRow(-1);
    toast.error("Row deleted");
  };

  console.log("editedObservations",editedObservations)

  const handleScoreChange = (index, value) => {
    setIsSaved(false);
    // Ensure the value is a number and within the range [0, 2]
    const parsedValue = parseFloat(value);
    const scoreValue = isNaN(parsedValue)
      ? ""
      : Math.min(2.0, Math.max(0, parsedValue));

    const updatedScores = [...scores];
    updatedScores[index]["Score Obtained"] = scoreValue;
    setScores(updatedScores);
  };

  const saveCriticalObservations = async (complete) => {
    try {
      // Create a new array with objects excluding the 'id' field
      const observationsToSave = criticalObservations.map(
        ({ id, ...observation }) => observation
      );

      // if (observationsToSave.length === 0) {
      //   console.log("No observations to save.");
      //   return;
      // }

      const payload = {
        criticalObservations: observationsToSave,
        report_id: ReportUID,
      };

      const endpoint =
        complete === true
          ? `${config.PATH}/api/save-critical-cmv-observations`
          : `${config.PATH}/api/save-critical-observations`;

      await axios.post(endpoint, payload);

      console.log("Critical observations saved successfully.");
    } catch (error) {
      console.error("Error saving critical observations:", error.message);
    }
  };

  const isAnyScoreEmpty = () => {
    return scores.some((score) => score["Score Obtained"] === "");
  };

  const handleObservationEdit = (index, e) => {
    setDisableSaveNext(true);
    const updatedObservations = [...criticalObservations];
    updatedObservations[index].observation = e.target.value;
    setCriticalObservations(updatedObservations);
  };

  const updateOrgReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      axios.post(`${config.PATH}/api/update-org-report-status/${org_id}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateSiteReportStatus = () => {
    try {
      const org_id = selectedOrganization.value;
      const site_name = selectedSite.value;
      axios.post(
        `${config.PATH}/api/update-site-report-status/${org_id}/${site_name}`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleComplete = async () => {
    if (!isSaved) {
      toast.warning("Please save the report before marking as complete.");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to mark this report as complete? Once done action cannot be reversed and you cannot edit the report.`
    );

    if (!userConfirmed) {
      return;
    }
    setIsComplete(true);
    handleSave(true);
    closeReport();
  };

  const customSelectStylesCreatable = {
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      cursor: "pointer",
      width: "150px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      borderLeft: "none",
      padding: 0,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      position: "absolute",
      width: "150px",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px",
      overflowY: "auto",
    }),
  };

  useEffect(() => {
    const uniqueCategories = new Set();
    const filteredOptions = [];

    allData.data.forEach((e) => {
      if (area.includes(e.area) && !uniqueCategories.has(e.category)) {
        filteredOptions.push({ value: e.category, label: e.category });
        uniqueCategories.add(e.category);
      }
    });

    setCategoryOptions(filteredOptions);
  }, [getAllData, area]);

  const getObservationVariants = async (observation, index) => {
    try {
      if (isEditing && currentEditedRow !== index) {
        toast.warn("Please finish editing the current row.");
        return;
      }
      setOpenVariantModal(true);
      const payload = {
        observation: observation,
        report: "new",
      };
      const response = await axios.post(
        `${config.PATH}/api/search-by-observation`,
        payload
      );
      setObservationVariants(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const closeVariantModal = () => {
    setOpenVariantModal(false);
  };

  const handleConfirmSelection = (selectedVariants, removedItems) => {
    let map = {};

    const getId = (item) => item.sr_no || item.id;

    // Update both states with the selected variants
    setSelectedObservations(
      [...selectedObservations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );

    map = {};
    setObservations(
      [...observations, ...selectedVariants].filter((e) => {
        const id = getId(e);
        if (!(map[id] || removedItems.includes(id))) {
          map[id] = true;
          return true;
        } else {
          return false;
        }
      })
    );
  };

  const criticalityOptions = [
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];

  useEffect(() => {
  handleEmptyFields();
}, [newRow]);

const handleEmptyFields = () => {
  const isEmpty = Object.entries(newRow).every(([key, value]) => {
    if (key === 'is_selected') return true; // ignore the is_selected field
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "";
    }
  });
  if (isEmpty) {
    setIsRowSaved(true); // Set isNewRowSaved to true if all fields are empty
  }
};

  const handleChangeNewRow = (e, field) => {
    const value = e.target.value;
    if ((field === 'area' || field === 'category' || field === 'criticality') && value.length > 100) {
      alert(`${field} cannot exceed 100 characters.`);
      return; // Do not update the state if validation fails
    }
    setNewRow((prev) => ({ ...prev, [field]: value, is_selected: 1 }));
    setIsRowSaved(false); // Set isNewRowSaved to false if any change is made
  };

  // const handleChangeNewRow = (e, field) => {
  //   const value = e.target.value;
  // if ((field === 'area' || field === 'category' || field === 'criticality') && value.length > 100) {
  //   alert(`${field} cannot exceed 100 characters.`);
  //   return; // Do not update the state if validation fails
  // }
  //   setNewRow((prev) => ({ ...prev, [field]: value, is_selected: 1 }));
  // };

  // const handleAddRow = () => {
  //   // Check if all fields are empty
  //   const areAllFieldsEmpty = Object.values(newRow).every(
  //     (value) => value === "" || (Array.isArray(value) && value.length === 0)
  //   );

  //   if (areAllFieldsEmpty) {
  //     alert("Cannot add empty row.");
  //     return;
  //   }
  //   selectedObservations.push({ ...newRow, imageUrls: [] });
  //   observations.push({ ...newRow, imageUrls: [] });
  //   setNewRow({
  //     area: "",
  //     category: "",
  //     check_points: "",
  //     observation: "",
  //     criticality: "",
  //     recommendations: "",
  //     is_reference: "",
  //     imageUrls: [],
  //   });
  // };

  // const handleAddRow = () => {
  //   // Check if area or observation fields are empty
  //   if (!newRow.area.trim() || !newRow.observation.trim()) {
  //     alert("Area and Observation fields cannot be empty.");
  //     return;
  //   }
  
  //   // Check if all fields are empty
  //   const areAllFieldsEmpty = Object.values(newRow).every(
  //     (value) => value === "" || (Array.isArray(value) && value.length === 0)
  //   );
  
  //   if (areAllFieldsEmpty) {
  //     alert("Cannot add empty row.");
  //     return;
  //   }
    
  //   selectedObservations.push({ ...newRow, imageUrls: [] });
  //   observations.push({ ...newRow, imageUrls: [] });
  //   setNewRow({
  //     area: "",
  //     category: "",
  //     check_points: "",
  //     observation: "",
  //     criticality: "",
  //     recommendations: "",
  //     is_reference: "",
  //     imageUrls: [],
  //   });
  // };

  const handleAddRow = () => {
    // Check if area or observation fields are empty
    if (!newRow.area.trim() || !newRow.observation.trim() || !newRow.recommendations.trim() || !newRow.is_reference.trim()) {
      alert("Area, Observation, Recommendations & IS Reference fields cannot be empty.");
      return;
    }
  
    // Replace empty fields with "N/A"
    const updatedRow = {
      ...newRow,
      area: newRow.area.trim() || "N/A",
      category: newRow.category.trim() || "N/A",
      check_points: newRow.check_points.trim() || "N/A",
      observation: newRow.observation.trim() || "N/A",
      criticality: newRow.criticality.trim() || "N/A",
      recommendations: newRow.recommendations.trim() || "N/A",
      is_reference: newRow.is_reference.trim() || "N/A",
      imageUrls: newRow.imageUrls.length > 0 ? newRow.imageUrls : [],
    };
  
    // Check if all fields are empty (excluding imageUrls)
    const areAllFieldsEmpty = Object.values(updatedRow).every(
      (value) => value === "" || (Array.isArray(value) && value.length === 0)
    );
  
    if (areAllFieldsEmpty) {
      alert("Cannot add empty row.");
      return;
    }

    setEditedObservations((prevObservations) => [...prevObservations, updatedRow]);
    setIsReportEdited(true);

    selectedObservations.push(updatedRow);
    observations.push(updatedRow);
    setNewRow({
      area: "",
      category: "",
      check_points: "",
      observation: "",
      criticality: "",
      recommendations: "",
      is_reference: "",
      imageUrls: [],
    });
  };

  const handleChangeFacilityInfo = (event) => {
    const { name, value } = event.target;
    setFacilityInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleNewKeyChange = (event) => {
    setNewKey(event.target.value);
  };

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleAddNewField = () => {
    if (newKey && newValue) {
      const keyExists = facilityInfo.hasOwnProperty(newKey);
      const valueExists = facilityInfo[newKey] === newValue;
  
      if (keyExists) {
        alert("This field already exists.");
        return;
      }
  
      setFacilityInfo((prevInfo) => ({
        ...prevInfo,
        [newKey]: newValue,
      }));
      setNewKey("");
      setNewValue("");
    } else {
      alert("Please fill both the fields before adding.");
    }
  };
  

  // const handleAddNewField = () => {
  //   if (newKey && newValue) {
  //     setFacilityInfo((prevInfo) => ({
  //       ...prevInfo,
  //       [newKey]: newValue,
  //     }));
  //     setNewKey("");
  //     setNewValue("");
  //   } else {
  //     alert("Please fill both the fields before adding.");
  //   }
  // };

  const saveFacilityInfo = async () => {
    try {
      const payload = {
        report_id: ReportUID,
        facility_info: facilityInfo,
      };
      const res = await axios.post(
        `${config.PATH}/api/save-electrical-facility-info`,
        payload
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleRemoveField = (key) => {
    if (Object.keys(facilityInfo).length === 1) {
      alert("Cannot remove the last field.");
      return;
    }
  
    const updatedFacilityInfo = { ...facilityInfo };
    delete updatedFacilityInfo[key];
    setFacilityInfo(updatedFacilityInfo);
  };
  


  if (screenNumber === 1) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">COVER PAGE</div>
                    <br />
                    <div className="image-container">
                      <img
                        src={Electrical_Cover}
                        alt="Image"
                        className="image"
                      />
                      <div className="cover-details">
                        <p className="cover-text">
                          {selectedOrganization.label}
                        </p>
                        <p className="cover-text">Electrical Audit</p>
                        {/* <p className="cover-text">{`${startDate.getDate()}-${
                startDate.getMonth() + 1
              }-${startDate.getFullYear()} to ${endDate.getDate()}-${
                endDate.getMonth() + 1
              }-${endDate.getFullYear()}`}</p> */}
                        <p className="cover-text">
                          {startDate.getTime() === endDate.getTime()
                            ? `${startDate.getDate()}-${
                                startDate.getMonth() + 1
                              }-${startDate.getFullYear()}`
                            : `${startDate.getDate()}-${
                                startDate.getMonth() + 1
                              }-${startDate.getFullYear()} to ${endDate.getDate()}-${
                                endDate.getMonth() + 1
                              }-${endDate.getFullYear()}`}
                        </p>
                        <p className="cover-text">{selectedSite.label}</p>

                        <a
                          style={{ textTransform: "lowercase" }}
                          href="https://www.momentumindia.in/"
                        >
                          www.momentumindia.in
                        </a>
                      </div>
                    </div>
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handleEdit}
                  style={{ background: "#efc71d" }}
                >
                  Back
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 2) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      BACKGROUND &#8208; PROJECT BRIEF
                    </div>
                    <br />
                    <TextareaAutosize
                      value={backgroundBrief}
                      onChange={(e) => handleChange(e, "background")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 3) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      UNDERSTANDING OF THE REVIEW REPORT &#8208; CONTENTS.
                    </div>
                    <br />
                    <TextareaAutosize
                      value={contents}
                      onChange={(e) => handleChange(e, "contents")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 4) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">EXECUTIVE SUMMARY</div>
                    <br />
                    <TextareaAutosize
                      value={exeSummary}
                      onChange={(e) => handleChange(e, "exe")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } 
  else if (screenNumber === 5) {
    return (
      <Modal open={true} onClose={handleClose}>
  <div className="review-modal-container">
    <div className="review-modal-content">
      <div className="review-modal-header">
        <Typography variant="h5">Preview Report</Typography>
        <button className="custom-close-button" onClick={handleClose}>
          &#10005; {/* Unicode for 'X' */}
        </button>
      </div>
      <div className="review-modal-body" style={{ overflowY: "auto" }}>
        <Typography variant="body1">
          <div className="sub-headings">ACADEMIC INFORMATION</div>
          <br />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>FACILITY INFORMATION</TableCell>
                  <TableCell>COMMENTS & NOTES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {facilityInfo &&
                  Object.entries(facilityInfo).map(([key, value], index) => (
                    <TableRow key={index}>
                      <TableCell>{key}:</TableCell>
                      <TableCell style={{display:"flex"}}>
                        <textarea
                          value={value}
                          onChange={(e) =>
                            handleChangeFacilityInfo({
                              target: { name: key, value: e.target.value },
                            })
                          }
                          placeholder="Enter your text here"
                          style={{ width: "100%", minHeight: "80px" }}
                        />
                        <IconButton
              onClick={() => handleRemoveField(key)}
              style={{ color: "red", marginLeft: "10px" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell>
                    <TextField
                      label="New Field Name"
                      value={newKey}
                      onChange={handleNewKeyChange}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <textarea
                        value={newValue}
                        onChange={(e) =>
                          handleNewValueChange({ target: { value: e.target.value } })
                        }
                        placeholder="Enter your text here"
                        style={{ width: "100%", minHeight: "80px" }}
                      />
                      <button
                        onClick={handleAddNewField}
                        className="button-styles"
                        style={{
                          background: "#efc71d",
                          marginLeft: "10px",
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
      </div>
      <hr />
      <div className="review-modal-footer">
        <button
          className="button-styles"
          onClick={handleSave}
          style={{ background: "#efc71d" }}
        >
          Save
        </button>
        <button
          className="button-styles"
          onClick={handlePrev}
          style={{ background: "#efc71d" }}
        >
          &#171; Prev
        </button>
        <button
          className="button-styles"
          color="primary"
          onClick={handleNext}
          style={{ background: "#efc71d" }}
        >
          Next &#187;
        </button>
      </div>
    </div>
  </div>
</Modal>
);
  } else if (screenNumber === 6) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CRITICAL OBSERVATIONS</div>
                    <br />
                    <div className="critical-observations-div">
                      {criticalObservations.length === 0 ? (
                        <div className="no-observations">
                          <em>No critical observations</em>
                        </div>
                      ) : (
                        criticalObservations.map((observation, index) => (
                          <div key={index} className="observation-item">
                            {/* <li>
                              <span>{observation.observation}</span>
                            </li> */}
                            <textarea
                              onChange={(e) => handleObservationEdit(index, e)}
                              style={{ width: "100%", fontFamily: "inherit" }}
                              value={observation.observation}
                            />
                            &nbsp;
                            <CancelIcon
                              onClick={() => removeItem(index)}
                              className="cancel-icon"
                            >
                              &#10005;
                            </CancelIcon>
                          </div>
                        ))
                      )}
                    </div>
                    <br />
                    <textarea
                      onChange={(e) => handleChange(e, "other details")}
                      value={otherDetails}
                      placeholder="Other Details..."
                      style={{
                        width: "99%",
                        height: "10vh",
                        fontFamily: "inherit",
                      }}
                    />
                  </div>
                </Typography>
              </div>

              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  disabled={disableSaveNext}
                  className="button-styles"
                  onClick={handlePrev}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  disabled={disableSaveNext}
                  style={{
                    background: disableSaveNext ? "lightgrey" : "#efc71d",
                  }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 7) {
    return (
      <>
        <VariantsModal
          data={observationVariants}
          open={openVairantModal}
          handleClose={closeVariantModal}
          handleConfirmSelection={handleConfirmSelection}
        />
        <ImageViewerModal
          imageUrl={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="sub-headings" style={{ fontWeight: 500 }}>
                CRITICAL OBSERVATIONS, RECOMMENDATIONS & REASONING - ELECTRICAL
                SAFETY
              </div>
              <br />
              <div className="review-modal-body">
                <div className="table-container">
                  <TableContainer component={Paper} className="table-scroll">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sr. No.</TableCell>
                          <TableCell>Areas</TableCell>
                          <TableCell>Categories</TableCell>
                          <TableCell>Check Point</TableCell>
                          <TableCell>Observation</TableCell>
                          <TableCell>Criticality</TableCell>
                          <TableCell>Recommendation</TableCell>
                          <TableCell>IS Reference</TableCell>
                          <TableCell>Photo Evidences</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedObservations.map((observation, index) => (
                          <TableRow
                            key={index}
                            className={
                              (isEditing && currentEditedRow === index) ||
                              !isEditing
                                ? "even-row"
                                : "odd-row"
                            }
                            style={
                              observation.variant === true
                                ? { backgroundColor: "#f2f2f2" }
                                : {}
                            }
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div className="cell-content">
                                <CreatableSelect
                                  styles={customSelectStylesCreatable}
                                  placeholder="Area"
                                  options={areaOptions()}
                                  defaultValue={{
                                    label: observation.area,
                                    value: observation.area,
                                  }}
                                  // value={{
                                  //   label: observation.area,
                                  //   value: observation.area,
                                  // }}
                                  isSearchable
                                  onChange={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "area",
                                      observation.area,
                                      observation
                                    )
                                  }
                                  isDisabled={
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                  }
                                />
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div className="cell-content">
                                <CreatableSelect
                                  styles={customSelectStylesCreatable}
                                  placeholder="Category..."
                                  options={categoryOptions}
                                  defaultValue={{
                                    label: observation.category,
                                    value: observation.category,
                                  }}
                                  // value={{
                                  //   label: observation.category,
                                  //   value: observation.category,
                                  // }}
                                  isSearchable
                                  onChange={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "category",
                                      observation.category,
                                      observation
                                    )
                                  }
                                  isDisabled={
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                  }
                                />
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    !observation.variant &&
                                    ((isEditing &&
                                      currentEditedRow === index) ||
                                      !isEditing)
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "check_points",
                                      observation.check_points,
                                      observation
                                    )
                                  }
                                >
                                  {observation.check_points}
                                </span>
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  onClick={(e) =>
                                    isEditing
                                      ? handleCellEdit(
                                          e,
                                          index,
                                          "check_points",
                                          observation.check_points,
                                          observation
                                        )
                                      : null
                                  }
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    !observation.variant &&
                                    ((isEditing &&
                                      currentEditedRow === index) ||
                                      !isEditing)
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "observation",
                                      observation.observation,
                                      observation
                                    )
                                  }
                                >
                                  {observation.observation}
                                </span>
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  onClick={(e) =>
                                    isEditing
                                      ? handleCellEdit(
                                          e,
                                          index,
                                          "observation",
                                          observation.observation,
                                          observation
                                        )
                                      : null
                                  }
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <Select
                                styles={customSelectStylesCreatable}
                                placeholder="Criticality"
                                options={criticalityOptions}
                                defaultValue={{
                                  label: observation.criticality,
                                  value: observation.criticality,
                                }}
                                // value={{
                                //   label: observation.criticality,
                                //   value: observation.criticality,
                                // }}
                                isSearchable
                                onChange={(e) =>
                                  handleCellEdit(
                                    e,
                                    index,
                                    "criticality",
                                    observation.criticality,
                                    observation
                                  )
                                }
                                isDisabled={
                                  (isEditing && currentEditedRow !== index) ||
                                  observation.variant === true
                                }
                              />
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell className="editable-cell">
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    !observation.variant &&
                                    ((isEditing &&
                                      currentEditedRow === index) ||
                                      !isEditing)
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "recommendations",
                                      observation.recommendations,
                                      observation
                                    )
                                  }
                                >
                                  {observation.recommendations}
                                </span>
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  onClick={(e) =>
                                    isEditing
                                      ? handleCellEdit(
                                          e,
                                          index,
                                          "recommendations",
                                          observation.recommendations,
                                          observation
                                        )
                                      : null
                                  }
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell
                              className="editable-cell"
                              style={{ height: "100px" }}
                            >
                              <div
                                className="cell-content"
                                style={{
                                  color:
                                    (isEditing && currentEditedRow !== index) ||
                                    observation.variant === true
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                <span
                                  contentEditable={
                                    !observation.variant &&
                                    ((isEditing &&
                                      currentEditedRow === index) ||
                                      !isEditing)
                                  }
                                  onInput={(e) =>
                                    handleCellEdit(
                                      e,
                                      index,
                                      "is_reference",
                                      observation.is_reference,
                                      observation
                                    )
                                  }
                                >
                                  {observation.is_reference}
                                </span>
                              </div>
                              {!observation.variant && (
                                <EditOutlinedIcon
                                  onClick={(e) =>
                                    isEditing
                                      ? handleCellEdit(
                                          e,
                                          index,
                                          "is_reference",
                                          observation.is_reference,
                                          observation
                                        )
                                      : null
                                  }
                                  className="edit-icon"
                                  fontSize="small"
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <div className="image-container">
                                {observation.imageUrls?.length > 0 ? (
                                  <div className="image-item">
                                    {observation.imageUrls.map(
                                      (imageUrl, imgIndex) => (
                                        <div
                                          style={{ display: "flex" }}
                                          key={imgIndex}
                                        >
                                          <img
                                            src={imageUrl}
                                            alt={`Image ${imgIndex + 1}`}
                                            className="photo-image-saved"
                                            onClick={() =>
                                              setSelectedImage(imageUrl)
                                            } // Set selected image on click
                                            style={{ cursor: "pointer" }}
                                          />
                                          <CancelIcon
                                            onClick={() =>
                                              handleRemoveImage(index, imgIndex)
                                            }
                                            className="cancel-icon"
                                            style={{
                                              pointerEvents:
                                                isEditing &&
                                                currentEditedRow !== index
                                                  ? "none"
                                                  : "auto",
                                            }}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="upload-container">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleImageUpload(index, e.target.files)
                                  }
                                  multiple
                                  style={{ color: "transparent" }}
                                  disabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                />
                                {observation.imageUrls?.length === 0 && (
                                  <div className="no-file-chosen">
                                    No file chosen
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {!observation.variant && (
                                  <InfoIcon
                                    onClick={() =>
                                      getObservationVariants(
                                        observation.observation,
                                        index
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                    isDisabled={
                                      isEditing && currentEditedRow !== index
                                    }
                                  />
                                )}
                                {!observation.variant && (
                                  <PlaylistAddCircleIcon
                                    onClick={() => handleDuplicateRow(index)}
                                    style={{ cursor: "pointer" }}
                                    isDisabled={
                                      isEditing && currentEditedRow !== index
                                    }
                                  />
                                )}
                                <DeleteForeverIcon
                                  onClick={() => handleDeleteRow(index)}
                                  style={{ cursor: "pointer" }}
                                  isDisabled={
                                    isEditing && currentEditedRow !== index
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* Add new row for user input */}
                        <TableRow>
                          <TableCell>
                            {selectedObservations.length + 1}
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.area}
                              onChange={(e) => handleChangeNewRow(e, "area")}
                              placeholder="Area"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.category}
                              onChange={(e) =>
                                handleChangeNewRow(e, "category")
                              }
                              placeholder="Category"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.check_points}
                              onChange={(e) =>
                                handleChangeNewRow(e, "check_points")
                              }
                              placeholder="Check Point"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.observation}
                              onChange={(e) =>
                                handleChangeNewRow(e, "observation")
                              }
                              placeholder="Observation"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.criticality}
                              onChange={(e) =>
                                handleChangeNewRow(e, "criticality")
                              }
                              placeholder="Criticality"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.recommendations}
                              onChange={(e) =>
                                handleChangeNewRow(e, "recommendations")
                              }
                              placeholder="Recommendations"
                            />
                          </TableCell>
                          <TableCell
                            className="editable-cell"
                            style={{ height: "100px" }}
                          >
                            <textarea
                              className="input-field"
                              value={newRow.is_reference}
                              onChange={(e) =>
                                handleChangeNewRow(e, "is_reference")
                              }
                              placeholder="IS Reference"
                            />
                          </TableCell>
                          <TableCell>
                            <div className="image-container">
                              <div className="upload-container">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleImageUpload(
                                      selectedObservations.length,
                                      e.target.files
                                    )
                                  }
                                  multiple
                                  style={{ color: "transparent" }}
                                  disabled
                                />
                                {newRow.imageUrls?.length === 0 && (
                                  <div className="no-file-chosen">No file chosen</div>
                                )}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CheckIcon
                                onClick={handleAddRow}
                                style={{ cursor: "pointer", color: "green" }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
    // return (
    //   <>
    //     <VariantsModal
    //       data={observationVariants}
    //       open={openVairantModal}
    //       handleClose={closeVariantModal}
    //       handleConfirmSelection={handleConfirmSelection}
    //     />
    //     <ImageViewerModal
    //       imageUrl={selectedImage}
    //       onClose={() => setSelectedImage(null)}
    //     />
    //     <Modal open={open} onClose={handleClose}>
    //       <div className="review-modal-container">
    //         <div className="review-modal-content">
    //           <div className="review-modal-header">
    //             <Typography variant="h5">Preview Report</Typography>
    //             <button
    //             className="custom-close-button"
    //             onClick={handleClose}
    //           >
    //             &#10005; {/* Unicode for 'X' */}
    //           </button>
    //           </div>
    //           <div className="sub-headings" style={{fontWeight:500}}>
    //           CRITICAL OBSERVATIONS, RECOMMENDATIONS & REASONING - ELECTRICAL SAFETY
    //           </div>
    //           <br/>
    //           <div className="review-modal-body">
    //             <div className="table-container">
    //               <TableContainer component={Paper} className="table-scroll">
    //                 <Table>
    //                   <TableHead>
    //                     <TableRow>
    //                       <TableCell>Sr. No.</TableCell>
    //                       <TableCell>Areas</TableCell>
    //                       <TableCell>Categories</TableCell>
    //                       <TableCell>Check Point</TableCell>
    //                       <TableCell>Observation</TableCell>
    //                       <TableCell>Criticality</TableCell>
    //                       <TableCell>Recommendation</TableCell>
    //                       <TableCell>IS Reference</TableCell>
    //                       <TableCell>Photo Evidences</TableCell>
    //                       <TableCell>Actions</TableCell>
    //                     </TableRow>
    //                   </TableHead>
    //                   <TableBody>
    //                     {selectedObservations.map((observation, index) => (
    //                       <TableRow
    //                         key={index}
    //                         // className={index % 2 === 0 ? "even-row" : "odd-row"}
    //                         className={
    //                           (isEditing && currentEditedRow === index) ||
    //                           !isEditing
    //                             ? "even-row"
    //                             : "odd-row"
    //                         }
    //                         style={
    //                           observation.variant === true
    //                             ? { backgroundColor: "#f2f2f2" }
    //                             : {}
    //                         }
    //                       >
    //                         <TableCell>{index + 1}</TableCell>

    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Area"
    //                               options={areaOptions()}
    //                               defaultValue={{
    //                                 label: observation.area,
    //                                 value: observation.area,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "area",
    //                                   observation.area,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content">
    //                             <CreatableSelect
    //                               styles={customSelectStylesCreatable}
    //                               placeholder="Category..."
    //                               options={categoryOptions}
    //                               defaultValue={{
    //                                 label: observation.category,
    //                                 value: observation.category,
    //                               }}
    //                               isSearchable
    //                               onChange={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "category",
    //                                   observation.category,
    //                                   observation
    //                                 )
    //                               }
    //                               isDisabled={
    //                                 (isEditing && currentEditedRow !== index) ||
    //                                 observation.variant === true
    //                               }
    //                             />
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>

    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content" style={{color:(isEditing && currentEditedRow !== index) || observation.variant === true?"grey":"black"}}>
    //                             <span
    //                               // contentEditable={
    //                               //   (isEditing && currentEditedRow === index) ||
    //                               //   !isEditing
    //                               // }
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "check_points",
    //                                   observation.check_points,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.check_points}
    //                             </span>
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "check_points",
    //                                       observation.check_points
    //                                         ? observation.check_points
    //                                         : "Check Point",
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content" style={{color:(isEditing && currentEditedRow !== index) || observation.variant === true?"grey":"black"}}>
    //                             <span
    //                               // contentEditable={
    //                               //   (isEditing && currentEditedRow === index) ||
    //                               //   !isEditing
    //                               // }
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "observation",
    //                                   observation.observation,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.observation}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "observation",
    //                                       observation.observation,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >

    //                           <Select
    //                             styles={customSelectStylesCreatable}
    //                             placeholder="Criticality"
    //                             options={criticalityOptions}
    //                             defaultValue={{
    //                               label: observation.criticality,
    //                               value: observation.criticality,
    //                             }}
    //                             isSearchable
    //                             onChange={(e) =>
    //                               handleCellEdit(
    //                                 e,
    //                                 index,
    //                                 "criticality",
    //                                 observation.criticality,
    //                                 observation
    //                               )
    //                             }
    //                             isDisabled={
    //                               (isEditing && currentEditedRow !== index) ||
    //                               observation.variant === true
    //                             }
    //                           />
    //                           {!observation.variant && <EditOutlinedIcon
    //                             className="edit-icon"
    //                             fontSize="small"
    //                           />}
    //                         </TableCell>
    //                         <TableCell className="editable-cell">
    //                           <div className="cell-content" style={{color:(isEditing && currentEditedRow !== index) || observation.variant === true?"grey":"black"}}>
    //                             <span
    //                               // contentEditable={
    //                               //   (isEditing && currentEditedRow === index) ||
    //                               //   !isEditing
    //                               // }
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "recommendations",
    //                                   observation.recommendations,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.recommendations}
    //                             </span>
    //                           </div>

    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "recommendations",
    //                                       observation.recommendations,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell
    //                           className="editable-cell"
    //                           style={{ height: "100px" }}
    //                         >
    //                           <div className="cell-content" style={{color:(isEditing && currentEditedRow !== index) || observation.variant === true?"grey":"black"}}>
    //                             <span
    //                               // contentEditable={
    //                               //   (isEditing && currentEditedRow === index) ||
    //                               //   !isEditing
    //                               // }
    //                               contentEditable={
    //                                 !observation.variant &&
    //                                 ((isEditing &&
    //                                   currentEditedRow === index) ||
    //                                   !isEditing)
    //                               }
    //                               onInput={(e) =>
    //                                 handleCellEdit(
    //                                   e,
    //                                   index,
    //                                   "is_reference",
    //                                   observation.is_reference,
    //                                   observation
    //                                 )
    //                               }
    //                             >
    //                               {observation.is_reference}
    //                             </span>
    //                           </div>
    //                           {!observation.variant && (
    //                             <EditOutlinedIcon
    //                               onClick={(e) =>
    //                                 isEditing
    //                                   ? handleCellEdit(
    //                                       e,
    //                                       index,
    //                                       "is_reference",
    //                                       observation.is_reference,
    //                                       observation
    //                                     )
    //                                   : null
    //                               }
    //                               className="edit-icon"
    //                               fontSize="small"
    //                             />
    //                           )}
    //                         </TableCell>
    //                         <TableCell>
    //                           <div className="image-container">
    //                             {observation.imageUrls?.length > 0 ? (
    //                               <div className="image-item">
    //                                 {observation.imageUrls.map(
    //                                   (imageUrl, imgIndex) => (
    //                                     <div
    //                                       style={{ display: "flex" }}
    //                                       key={imgIndex}
    //                                     >
    //                                       <img
    //                                         src={imageUrl}
    //                                         alt={`Image ${imgIndex + 1}`}
    //                                         className="photo-image-saved"
    //                                         onClick={() =>
    //                                           setSelectedImage(imageUrl)
    //                                         } // Set selected image on click
    //                                         style={{ cursor: "pointer" }}
    //                                       />
    //                                       <CancelIcon
    //                                         onClick={() =>
    //                                           handleRemoveImage(index, imgIndex)
    //                                         }
    //                                         className="cancel-icon"
    //                                         style={{
    //                                           pointerEvents:
    //                                             isEditing &&
    //                                             currentEditedRow !== index
    //                                               ? "none"
    //                                               : "auto",
    //                                         }}
    //                                       />
    //                                     </div>
    //                                   )
    //                                 )}
    //                               </div>
    //                             ) : (
    //                               ""
    //                             )}
    //                           </div>
    //                           <div className="upload-container">
    //                             <input
    //                               type="file"
    //                               accept="image/*"
    //                               onChange={(e) =>
    //                                 handleImageUpload(index, e.target.files)
    //                               }
    //                               multiple
    //                               style={{ color: "transparent" }}
    //                               disabled={
    //                                 isEditing && currentEditedRow !== index
    //                               }
    //                             />
    //                             {observation.imageUrls?.length === 0 && (
    //                               <div className="no-file-chosen">
    //                                 No file chosen
    //                               </div>
    //                             )}
    //                           </div>
    //                         </TableCell>
    //                         <TableCell>
    //                           <div
    //                             style={{
    //                               display: "flex",
    //                               justifyContent: "center",
    //                             }}

    //                           >
    //                             {!observation.variant && (
    //                               <InfoIcon
    //                                 onClick={() =>
    //                                   getObservationVariants(
    //                                     observation.observation,
    //                                     index
    //                                   )
    //                                 }
    //                                 style={{ cursor: "pointer" }}
    //                                 isDisabled={
    //                                   isEditing && currentEditedRow !== index
    //                                 }
    //                               />
    //                             )}
    //                             {!observation.variant && (
    //                               <PlaylistAddCircleIcon
    //                                 onClick={() => handleDuplicateRow(index)}
    //                                 style={{ cursor: "pointer" }}
    //                                 isDisabled={
    //                                   isEditing && currentEditedRow !== index
    //                                 }
    //                               />
    //                             )}
    //                             {
    //                               <DeleteForeverIcon
    //                                 onClick={() => handleDeleteRow(index)}
    //                                 style={{ cursor: "pointer" }}
    //                                 isDisabled={
    //                                   isEditing && currentEditedRow !== index
    //                                 }
    //                               />
    //                             }
    //                           </div>
    //                         </TableCell>
    //                       </TableRow>
    //                     ))}
    //                   </TableBody>
    //                 </Table>
    //               </TableContainer>
    //             </div>
    //           </div>
    //           <hr />
    //           <div className="review-modal-footer">
    //             <button className="button-styles" onClick={handleSave} style={{background:"#efc71d"}}>
    //               Save
    //             </button>
    //             <button className="button-styles" onClick={handlePrev} style={{background:"#efc71d"}}>
    //               &#171; Prev
    //             </button>
    //             <button
    //               className="button-styles"
    //               color="primary"
    //               onClick={() => handleNext()}
    //               style={{background:"#efc71d"}}
    //             >
    //               Next &#187;
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </Modal>
    //   </>
    // );
  }
  // else if (screenNumber === 7) {
  //   return (
  //     <>
  //       <Modal open={open} onClose={handleClose}>
  //         <div className="review-modal-container">
  //           <div className="review-modal-content">
  //             <div className="review-modal-header">
  //               <Typography variant="h5">Preview Report</Typography>
  //               <button
  //               className="custom-close-button"
  //               onClick={handleClose}
  //             >
  //               &#10005; {/* Unicode for 'X' */}
  //             </button>
  //             </div>
  //             <div className="review-modal-body"style={{overflowY:"auto"}}>
  //               <Typography variant="body1">
  //                 <div>
  //                   <div className="sub-headings">GLOBAL BEST PRACTICES</div>
  //                   <br />
  //                   <TextareaAutosize
  //                     value={bestPractice}
  //                     onChange={(e) => handleChange(e, "best_practice")}
  //                     placeholder="Enter your text here"
  //                     className="text-area"
  //                     style={{ background: "whitesmoke" }}
  //                   />
  //                 </div>
  //               </Typography>
  //             </div>
  //             <hr />
  //             <div className="review-modal-footer">
  //               <button className="button-styles" onClick={handleSave} style={{background:"#efc71d"}}>
  //                 Save
  //               </button>
  //               <button className="button-styles" onClick={handlePrev} style={{background:"#efc71d"}}>
  //                 &#171; Prev
  //               </button>
  //               <button
  //                 className="button-styles"
  //                 color="primary"
  //                 onClick={handleNext}
  //                 style={{background:"#efc71d"}}
  //               >
  //                 Next &#187;
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </Modal>
  //     </>
  //   );
  // }
  else if (screenNumber === 8) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">
                      Electrical Safety Scoring Table
                    </div>
                    <br />
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow style={{ background: "grey" }}>
                            <TableCell>Electrical Safety</TableCell>
                            <TableCell>Max Score</TableCell>
                            <TableCell>Score Obtained</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {scores.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>{row["Electrical Safety"]}</TableCell>
                              <TableCell>{row["Max Score"]}</TableCell>
                              <TableCell>
                                <input
                                  type="number"
                                  placeholder="Enter score"
                                  value={row["Score Obtained"]}
                                  onChange={(e) =>
                                    handleScoreChange(index, e.target.value)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow style={{ background: "#efc71d" }}>
                            <TableCell>Cumulative</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {scores.reduce(
                                (acc, row) =>
                                  acc + parseFloat(row["Max Score"] || 0),
                                0
                              )}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {cumulativeScore}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={() => handleNext()}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 9) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">THE WAY FORWARD</div>
                    <br />
                    <TextareaAutosize
                      value={theWayForward}
                      onChange={(e) => handleChange(e, "the_way_forward")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  color="primary"
                  onClick={handleNext}
                  style={{ background: "#efc71d" }}
                >
                  Next &#187;
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  } else if (screenNumber === 10) {
    return (
      <>
        <Modal open={open} onClose={handleClose}>
          <div className="review-modal-container">
            <div className="review-modal-content">
              <div className="review-modal-header">
                <Typography variant="h5">Preview Report</Typography>
                <button className="custom-close-button" onClick={handleClose}>
                  &#10005; {/* Unicode for 'X' */}
                </button>
              </div>
              <div className="review-modal-body" style={{ overflowY: "auto" }}>
                <Typography variant="body1">
                  <div>
                    <div className="sub-headings">CONCLUSION</div>
                    <br />
                    <TextareaAutosize
                      value={conclusion}
                      onChange={(e) => handleChange(e, "conclusion")}
                      placeholder="Enter your text here"
                      className="text-area"
                      style={{ background: "whitesmoke" }}
                    />
                  </div>
                </Typography>
              </div>
              <hr />
              <div className="review-modal-footer">
                <button
                  className="button-styles"
                  onClick={handleSave}
                  style={{ background: "#efc71d" }}
                >
                  Save
                </button>
                <button
                  className="button-styles"
                  onClick={handlePrev}
                  style={{ background: "#efc71d" }}
                >
                  &#171; Prev
                </button>
                <button
                  className="button-styles"
                  onClick={handleComplete}
                  // disabled={isComplete}
                  style={{ background: "#efc71d" }}
                >
                  Complete
                </button>
                <ExportSavedReportPDF
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  // imageUrlsByRow={imageUrlsByRow}
                  isSaved={isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={ReportUID}
                  startDate={startDate}
                  endDate={endDate}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  name={name}
                  facilityInfo={facilityInfo}
                />
                <ExportExcel
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  // imageUrlsByRow={imageUrlsByRow}
                  isSaved={isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={ReportUID}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  startDate={startDate}
                  endDate={endDate}
                  name={name}
                  facilityInfo={facilityInfo}
                />
                <ExportWordDoc
                  selectedOrganization={selectedOrganization}
                  selectedSite={selectedSite}
                  backgroundBrief={backgroundBrief}
                  contents={contents}
                  exeSummary={exeSummary}
                  criticalObservations={criticalObservations}
                  selectedObservations={selectedObservations}
                  selectedArea={selectedArea}
                  selectedCategory={selectedCategory}
                  recommendations={recommendations}
                  conclusion={conclusion}
                  selectedDateTime={selectedDateTime.split("T")[0]}
                  isSaved={isSaved}
                  scores={scores}
                  cumulativeScore={cumulativeScore}
                  otherDetails={otherDetails}
                  ReportUID={ReportUID}
                  bestPractice={bestPractice}
                  theWayForward={theWayForward}
                  startDate={startDate}
                  endDate={endDate}
                  name={name}
                  facilityInfo={facilityInfo}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
};

export default PreviewReportModal;